import React, { useEffect, useState, useContext } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useAccount, useDisconnect } from 'wagmi'
import { GetMappingSidebar } from "../sidebar/sidebar";

const MobileSidebar = ({ setMobileSidebar, setMainComponent }) => {
    let { address } = useAccount();
    const { disconnect } = useDisconnect()

    let items = [{ name: "Compose", icon: 'fa-pen', link: () => { setMobileSidebar(false); setMainComponent("Compose"); } },
    { name: "Inbox", icon: 'fa-mailbox', link: () => { setMobileSidebar(false); setMainComponent("Inbox"); } },
    { name: "Sent", icon: 'fa-paper-plane', link: () => { setMobileSidebar(false); setMainComponent("Sent"); } },
    { name: "Starred", icon: 'fa-flag', link: () => { setMobileSidebar(false); setMainComponent("Starred"); } },
    { name: "Bin", icon: 'fa-trash-can', link: () => { setMobileSidebar(false); setMainComponent("Bin"); } }
    ]

    return (
        <div style={{ zIndex: 99000000000000000, minHeight: window.innerHeight, background: "#fff", padding: "5%" }} className="font-semibold">

            <GetMappingSidebar paramsArray={items} setMainComponent={setMainComponent} setMobileSidebar={setMobileSidebar} />
        </div >
    )

}

export default MobileSidebar;
