import React, { useState, useContext, useEffect } from "react";
import HeaderRight from "./headerRight";
import HeaderSearch from "./headerSearch";
import MobileSidebar from "./mobileSidebar";
import Logo from "../../../../../1.resources/3.files/logo/w3mail.png";

const InboxHeader = ({ mobileSidebar, setMobileSidebar, setMainComponent }) => {


    return (
        <div>

            {/* <div className='hidden lg:block' >
                <div className="py-4 flex justify-between items-center px-5 md:px-4">
                    <div className="w-[720px] px-4">
                        <a href='/' className='flex items-center w-full cursor-pointer'>
                            <img src={Logo} alt="Logo" className="w-10" />
                            <p className='font-bold text-black text-2xl ml-2 whitespace-nowrap'>W3 <span className='text-gray-500 font-semibold tracking-widest'>Mail</span></p>
                        </a>
                    </div>
                    <div className='w-full'>
                        <HeaderSearch />
                    </div>

                    <div className="flex items-center justify-end w-full">
                        <HeaderRight />
                    </div>
                </div>
            </div> */}


            <div style={{ width: "100%" }} className={window.innerWidth < 1024 ? (mobileSidebar ? "slider slide-in block lg:hidden static top-[72px] " : "slider slide-out block lg:hidden static top-[72px]") : ""}>
                {mobileSidebar ? (
                    <MobileSidebar setMobileSidebar={setMobileSidebar} setMainComponent={setMainComponent} />
                ) : (<div />)}
            </div>
        </div>
    );

}

export default InboxHeader;
