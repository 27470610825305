import React from 'react';
import HeroImg from "../../../1.resources/3.files/images/hero90.png";
import HeroImg2 from "../../../1.resources/3.files/images/hero91.png";
import Banner from '../../0.global/header/banner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ScrollBox } from '../../0.global/scrollbox/scrollbox';

const Devices = ({ }) => {


    return (

        <div className='py-10 md:py-20 px-5 md:px-20 lg:px-60' >

            <p className='font-bold text-6xl md:text-center'>Access on your devices</p>
            <p className='md:text-center text-2xl mt-4 text-neutral-500'>Mail on your phone, tablet, laptop, and desktop.</p>

            <ScrollBox elements={
                <div className='md:flex mt-16 mb-16'>
                    <div className='md:w-4/12'>
                        <div className='flex justify-center'>
                            <img src="https://www.apple.com/v/apple-music/w/images/overview/device_iphone__c8l6gyaxoiky_large_2x.png" />
                        </div>
                        <div className='w-full'>
                            <p className='font-bold text-center mt-4 text-2xl'>iPhone</p>
                        </div>
                        <a >
                            <div className='flex justify-center items-center gap-x-2 mt-4'>
                                <p className='text-start text-main text-md'>Coming soon</p>
                                {/* <FontAwesomeIcon icon={['fas', 'fa-angle-right']} className="text-md text-main" /> */}
                            </div>
                        </a>
                    </div>
                    <div className='md:w-4/12 pt-20 md:pt-0'>
                        <div className='flex justify-center'>
                            <img src="https://www.apple.com/v/apple-music/w/images/overview/device_android__30ewuplq7wy6_large_2x.png" />
                        </div>
                        <div className='w-full'>
                            <p className='font-bold text-center mt-4 text-2xl'>Android</p>
                        </div>
                        <a >
                            <div className='flex justify-center items-center gap-x-2 mt-4'>
                                <p className='text-start text-main text-md'>Coming soon</p>
                                {/* <FontAwesomeIcon icon={['fas', 'fa-angle-right']} className="text-md text-main" /> */}
                            </div>
                        </a>
                    </div>
                    <div className='md:w-4/12 pt-20 md:pt-0'>
                        <div className='flex justify-center'>
                            <img src="https://www.apple.com/v/apple-music/w/images/overview/device_pc__epdb7iaktzqu_large_2x.png" />
                        </div>
                        <div className='w-full'>
                            <p className='font-bold text-center mt-4 text-2xl'>Web</p>
                        </div>
                        <a href='/inbox'>
                            <div className='flex justify-center items-center gap-x-2 mt-4'>
                                <p className='text-start text-main text-md'>Open on the Web</p>
                                <FontAwesomeIcon icon={['fas', 'fa-angle-right']} className="text-md text-main" />
                            </div>
                        </a>
                    </div>
                </div>
            } id="devices" />
        </div>


    )
}

export default Devices;
