import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import InboxCompose from "./compose";
import { parseEmail } from "./mail/parseEmail";
import Iframe from "./mail/iframe";
import { shortenaddress } from "../../../../../../1.resources/2.js/0.global/0.smallfunctions/global";
import { getWnsDomain } from "../../../../../../1.resources/2.js/0.global/3.api/callW3Api";
import { markAsRead } from "../../../../../../1.resources/2.js/0.global/4.app/updateEmail";
import { Tooltip } from "@material-tailwind/react";
import { GetTime } from "./inbox";
import RichTextEditor from 'react-rte';

const InboxEmail = ({ mainComponent, setMainComponent, emailItem, setReloadIndex, snackbar, setParamBody, setParamTo, readDirection }) => {

    const [item, setItem] = useState()
    const [itemSet, setItemSet] = useState()
    const [iframe, setIframe] = useState("<html></html>")
    const [iframeText, setIframeText] = useState("")
    const [contentType, setContentType] = useState("text")

    const [fromDomain, setFromDomain] = useState("")
    const [toDomain, setToDomain] = useState("")

    async function checkDomain(param, value) {
        if (param == "from") {
            let from = await getWnsDomain(value.from);
            if (from != "null") {
                setFromDomain(from);
            }
        } else {
            let to = await getWnsDomain(value.to);
            if (to != "null") {
                setToDomain(to);
            }
        }
    }

    async function parseBody(item) {
        if (item?.email?.contentType == "html") {
            let iframeContent = await parseEmail(item?.email?.body);
            setIframe(iframeContent);
            setContentType("html");
        } else {
            setContentType("text");
            setIframeText(item?.email?.body);
        }
    }

    useEffect(() => {
    }, [])
    useEffect(() => {

        setItem(emailItem);
        console.log(emailItem);
        checkDomain("from", emailItem);
        checkDomain("to", emailItem);
        if (emailItem.from) {
            setItemSet(true);
            if (readDirection == "from") {
                markAsRead(emailItem.id, "from");
            } else {
                markAsRead(emailItem.id, "to");
            }
        }
        parseBody(emailItem);
    }, [emailItem])

    function goBack() {
        setMainComponent("Inbox");
        setReloadIndex(Math.floor((Math.random() * 1000) + 1))
    }

    function reply(address) {
        setParamBody(null);
        setParamTo(address);
        setMainComponent("Compose");
    }

    function forward(email) {
        setParamTo(null);
        setParamBody(email.contentType == "html" ? email.body : RichTextEditor.createValueFromString(email.body, "text/html"));
        setMainComponent("Compose");
    }


    return (
        <div >
            {itemSet ? (
                <div className="py-10 px-8" id="upper">

                    <div onClick={() => goBack()} className="hover:bg-gray-100 w-10 h-10 flex justify-center items-center rounded-full cursor-pointer mb-4 -ml-3">
                        <FontAwesomeIcon icon={['fas', 'fa-arrow-left']} className="text-black text-90" />
                    </div>

                    <div className="flex items-center gap-x-4 border-b border-b-gray-200 pb-8">

                        <div className="w-full">
                            <p className="font-bold text-theme-text mt-1 text-2xl">{item?.email?.subject}</p>

                            <div className="mt-2">
                                <Tab item={item} fromDomain={fromDomain} toDomain={toDomain} />
                            </div>

                        </div>

                        <div>
                            <GetTime item={item} size={"sm"} />
                        </div>
                    </div>

                    <div className="mt-2 text-xl w-full" style={{ fontSize: "100%", width: "100%" }}>
                        {contentType == "html" ? (
                            <Iframe content={iframe} />
                        ) : (
                            <div className="mt-8">{iframeText}</div>
                        )}
                    </div>
                    <div className="mt-8 flex items-center">
                        <div onClick={() => reply(item.from)} className="flex items-center rounded-full border border-gray-200 p-2 px-6 cursor-pointer mr-1">
                            <FontAwesomeIcon icon={['fas', 'fa-reply']} className="text-sm" />
                            <p className="ml-2 font-semibold text-sm">Reply</p>
                        </div>
                        {/* <div onClick={() => forward(item.email)} className="flex items-center rounded-full border border-gray-200 p-2 px-6 cursor-pointer ml-1">
                            <FontAwesomeIcon icon={['fas', 'fa-share']} className="text-sm" />
                            <p className="ml-2 font-semibold text-sm">Forward</p>
                        </div> */}
                    </div>
                </div>
            ) : (<div />)}

        </div >
    );

}


export default InboxEmail;


const Tab = ({ item, fromDomain, toDomain }) => {
    const [copied, setCopied] = useState(false)


    function copy(value) {
        navigator.clipboard.writeText(value);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    }

    return (
        <div>
            <div className="flex items-center gap-x-8">
                <div>
                    <div className="flex gap-x-2 items-center mt-4">
                        <p className="text-gray-500 text-xs">From</p>
                        <div className="tooltip cursor-pointer" onClick={() => copy(item?.from)}>
                            <div className="ml-0 flex items-center gap-x-2 bg-gray-100 rounded-full p-2 px-4 border border-gray-200">
                                <p className="font-semibold text-text text-sm">{fromDomain !== "" ? fromDomain : shortenaddress(item?.from)}</p>
                            </div>
                            <div className="tooltiptext">
                                <p className="text-xs">{copied == true ? "Copied successfully" : item?.from + " (Click to Copy)"} </p>
                            </div>
                        </div>

                    </div>
                </div>

                <div>
                    <div className="flex gap-x-2 items-center mt-4">
                        <p className="text-gray-500 text-xs">To</p>
                        <div className="tooltip cursor-pointer" onClick={() => copy(item?.to)}>
                            <div className="ml-0 flex items-center gap-x-2 bg-gray-100 rounded-full p-2 px-4 border border-gray-200">
                                <p className="font-semibold text-text text-sm">{toDomain !== "" ? toDomain : shortenaddress(item?.to)}</p>
                            </div>
                            <div className="tooltiptext">
                                <p className="text-xs">{copied == true ? "Copied successfully" : item?.to + " (Click to Copy)"} </p>
                            </div>
                        </div>
                    </div>

                </div>
                <div>
                    <div className="flex gap-x-2 items-center mt-4">
                        <p className="text-gray-500 text-xs">IPFS</p>
                        <a className="tooltip cursor-pointer" href={"https://w3.infura-ipfs.io/ipfs/" + item.emailHash} target="_blank">
                            <div className="ml-0 flex items-center gap-x-2 bg-gray-100 rounded-full p-2 px-4 border border-gray-200">
                                <p className="font-semibold text-black text-sm">{shortenaddress(item?.emailHash)}</p>
                            </div>
                            <div className="tooltiptext">
                                <p className="text-xs">Open in IPFS</p>
                            </div>
                        </a>
                    </div>
                </div>
                <div>
                    <div className="flex gap-x-2 items-center mt-4">
                        <p className="text-gray-500 text-xs">Transaction</p>
                        <a className="tooltip cursor-pointer" href={process.env.REACT_APP_NETWORK === "main" ? "https://polygonscan.com/tx/" + item.transactionHash : "https://mumbai.polygonscan.com/tx/" + item.transactionHash} target="_blank">
                            <div className="ml-0 flex items-center gap-x-2 bg-gray-100 rounded-full p-2 px-4 border border-gray-200">
                                <p className="font-semibold text-black text-sm">{shortenaddress(item?.transactionHash)}</p>
                            </div>
                            <div className="tooltiptext">
                                <p className="text-xs">Open in Explorer</p>
                            </div>
                        </a>

                    </div>
                </div>


            </div>
        </div>
    )
}