

import React, { useEffect, useState, useContext } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Logo from "../../../1.resources/3.files/logo/w3mail6.png";
const WnsLogo = ({ color }) => {

    return (
        <a href='/' className='flex items-center w-full cursor-pointer'>
            <img src={Logo} alt="Logo" className="w-7" />
            <p className='font-bold text-xl ml-2 whitespace-nowrap text-black'>W3 <span className='text-gray-500 font-semibold tracking-widest'>Mail</span></p>
        </a>
    );

}

export default WnsLogo;
