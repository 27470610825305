import React, { useState, useContext, useEffect } from "react";
import InboxCompose from "./partials/compose";
import InboxInbox from "./partials/inbox";
import InboxEmail from "./partials/mail";



const InboxSection = ({ mainComponent, setMainComponent, snackbar, composeVisibility, setComposeVisibility }) => {
    const [emailItem, setEmailItem] = useState("");
    const [reloadIndex, setReloadIndex] = useState(0);
    const [paramTo, setParamTo] = useState(null);
    const [paramBody, setParamBody] = useState(null);
    const [readDirection, setReadDirection] = useState(null);

    useEffect(() => {
        console.log("mainComponent", mainComponent);
    }, [mainComponent])
    return (
        <div className="bg-white h-full w-full rounded-2xl border border-gray-200 flex-1">
            <div style={{ display: mainComponent == "Compose" ? "none" : "block" }}>
                <div style={{ display: mainComponent == "Mail" ? "none" : "block" }}>
                    <InboxInbox mainComponent={mainComponent} setMainComponent={setMainComponent} setEmailItem={setEmailItem} reloadIndex={reloadIndex} setReloadIndex={setReloadIndex} setReadDirection={setReadDirection} />
                </div>
                <div style={{ display: mainComponent == "Mail" ? "block" : "none" }}>
                    <InboxEmail mainComponent={mainComponent} setMainComponent={setMainComponent} readDirection={readDirection} emailItem={emailItem} setReloadIndex={setReloadIndex} snackbar={snackbar} setParamTo={setParamTo} setParamBody={setParamBody} />
                </div>
            </div>
            <div style={{ display: mainComponent == "Compose" ? "block" : "none" }}>
                <InboxCompose snackbar={snackbar} mainComponent={mainComponent} setMainComponent={setMainComponent} setParamTo={setParamTo} setParamBody={setParamBody} paramBody={paramBody} paramTo={paramTo} />
            </div>
        </div>
    );

}

export default InboxSection;
