import React from 'react';
import HeroImg from "../../../1.resources/3.files/images/hero90.png";
import Logo from "../../../1.resources/3.files/logo/w3mail.png";
import Banner from '../../0.global/header/banner';

import { ScrollBox } from '../../0.global/scrollbox/scrollbox';

const Hero = ({ }) => {


    return (

        <div className='flex flex-col justify-center items-center md:text-center pt-10 lg:pt-0 pb-10' id="hero" >


            <div className='w-full flex flex-col justify-center md:items-center text-start md:text-center pb-10 px-5 md:px-20 lg:px-40 md:px-0 pt-8' style={{}}>
                {/* <div className='flex flex-col items-center'>
                    <img src={Logo} alt="Logo" className="w-20 bg-white rounded-3xl p-3 shadow-sm" />
                    <p className='font-semibold text-xl ml-2 blackspace-nowrap text-black mt-4 '>W3 <span className='text-black font-semibold tracking-widest'>Mail</span></p>
                </div> */}
                <p className='md:text-8xl font-bold pt-0 text-black hero-heading pb-2 mt-8' >Meet <span className=''>Web3</span> e-mail</p>
                {/* <Banner /> */}

                <p className='text-3xl mt-6 w-full md:w-[700px] text-black font-semibold'>Most secure email platform
                    <br />
                    built on the Blockchain technology.</p>

                <div className='flex md:justify-center md:items-center mt-4 w-full'>
                    <div className=''>
                        <div>
                            <a href="/inbox" >
                                <button className='mt-8 p-3 px-4 bg-black text-white rounded-full font-bold text-sm' id="searchButton">Go to Inbox</button>
                            </a>
                        </div>
                        <div className='lg:hidden'>
                            <a href="/inbox" >
                                <button className='mt-4 p-3 px-2 text-black rounded-full font-bold text-sm' id="searchButton">Read Docs</button>
                            </a>
                        </div>
                    </div>
                </div>

            </div>
            <div className='md:px-20 lg:px-40 xl:px-40'>
                {/* <ScrollBox elements={ */}
                {/* <div className='rounded-3xl p-2 pt-4 px-3 pb-10 bg-black'>
                    <img src={HeroImg2} className='w-full mt-6 rounded-xl p-2 bg-black border border-gray-200' />
                </div> */}
                <div className='hidden md:block rounded-3xl px-5 pt-0 pb-5 lg:px-10 lg:pt-4 lg:pb-10 bg-black border-2 border-neutral-700'>
                    <img src={HeroImg} className='w-full mt-6 rounded-md bg-black border border-gray-200' />
                </div>

                {/* } id={"hero-image"} /> */}
            </div>
        </div>


    )
}

export default Hero;
