import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { colors } from '../../../../../../1.resources/1.css/colors'

export const SkeletonInbox = () => {
    let array = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    return (
        array.map((item, index) => (
            <SkeletonTheme key={index} baseColor={colors.gray100} highlightColor={colors.gray200}>
                <div className='px-16 flex justify-between items-center border-b border-b-gray-200 py-4'>
                    <div className='w-6/12'>
                        <Skeleton count={1} style={{ paddingRight: "10%", paddingBottom: "2%", marginBottom: "0.5%", zIndex: 100, borderRadius: "50px", width: "20px", height: "20px" }} />
                        <Skeleton count={1} style={{ paddingRight: "14%", paddingBottom: "1%", marginBottom: "0.5%", zIndex: 100, borderRadius: "50px", width: "20px", height: "20px" }} />
                        <Skeleton count={1} style={{ paddingRight: "50%", paddingBottom: "1%", marginBottom: "0.5%", zIndex: 100, borderRadius: "50px", width: "20px", height: "20px" }} />
                    </div>
                    <div className='w-6/12'>
                        <Skeleton count={1} style={{ paddingRight: "10%", paddingBottom: "1%", marginBottom: "0.5%", zIndex: 100, borderRadius: "50px", width: "20px", height: "20px", float: "right" }} />
                    </div>
                </div>
            </SkeletonTheme>
        )
        )
    )
}