import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const HeaderSearch = ({ }) => {
    const [searchDomain, setSearchDomain] = useState("")
    const [searchStatus, setSearchStatus] = useState("")
    const [searchStatusColor, setSearchStatusColor] = useState("")
    const [searchLoading, setSearchLoading] = useState(false)
    const [searchLink, setSearchLink] = useState("")
    const [searchActive, setSearchActive] = useState(false)

    async function searchBar(value) {
    }

    return (
        <div>
            <div id="searchDiv" className="bg-red-500" style={{ borderRadius: "12.5px", width: "100%", boxShadow: searchActive ? "0 0 0 2000px rgba(0, 0, 0, 0.5)" : "" }}>
                <div className="shadowDiv bg-white rounded-xl flex flex-between border border-gray-200" style={{ zIndex: "100000" }}>
                    <input id="searchInput" type="text" onChange={(e) => { searchBar(e.target.value) }} value={searchDomain} placeholder="Search" className="bg-transparent text-black w-full outline-none pl-6 py-3 text-md" ></input>
                    <a href={searchLink} className="bg-black p-1 rounded-r-xl px-4 flex justify-center items-center">
                        <FontAwesomeIcon icon={['fas', 'fa-search']} style={{ color: '#fff', fontSize: "80%" }} />
                    </a>
                </div>
                <div id="searchDiv" style={{ display: searchActive ? "block" : "none" }}>
                    <div className="shadowDiv rounded-md bg-white" style={{ zIndex: "10", position: "absolute", width: "100%", }}>
                        <div style={{ padding: "0%" }}>
                            <p className="text-xs font-bold border-b border-b-gray-200 px-4 py-2">Domains</p>
                            <a className="bg-white hover:bg-gray-100 cursor-pointer flex items-center text-sm px-4 py-2 rounded-md" href={searchLink}>
                                <div style={{ width: "70%" }}>
                                    <span className="font-bold text-black">{searchDomain}</span>
                                    <span className="text-black">.web3</span>
                                </div>
                                <div style={{ width: "30%", display: "flex", justifyContent: "flex-end" }}>
                                    <div style={{ display: searchLoading ? "block" : "none" }}>
                                        <FontAwesomeIcon icon={['fas', 'fa-circle-notch']} style={{ color: "#606060", fontSize: "100%" }} id="arrowicon" spin />
                                    </div>
                                    <p style={{ display: searchLoading ? "none" : "block", color: searchStatusColor, fontSize: "80%", fontWeight: "bold" }}>{searchStatus}</p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div >
        </div>
    );

}


export default HeaderSearch;
