import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { BsMailbox } from 'react-icons/bs';
import { FiSend, FiTrash2 } from 'react-icons/fi';
import { FaRegEnvelopeOpen } from 'react-icons/fa';
import { HiOutlinePencilAlt } from 'react-icons/hi';
import Logo from "../../../../../1.resources/3.files/logo/w3mail6.png";
import LogoWns from "../../../../../1.resources/3.files/logo/logo2.png";
import { useAccount } from 'wagmi'
import { shortenaddress } from "../../../../../1.resources/2.js/0.global/0.smallfunctions/global";
import { getWnsDomain } from "../../../../../1.resources/2.js/0.global/3.api/callW3Api";
import { AccountModal } from "../../../../0.global/wallet/connectWallet";

const InboxSidebar = ({ snackbar, mainComponent, setMainComponent, mobileSidebar, setMobileSidebar }) => {
    const { address } = useAccount()
    const [domain, setDomain] = useState("")
    const [accountModalOpen, setAccountModalOpen] = useState(false);

    let items = [{ name: "Compose", icon: 'fa-pen', link: () => setMainComponent("Compose") },
    { name: "Inbox", icon: 'fa-mailbox', link: () => setMainComponent("Inbox") },
    { name: "Sent", icon: 'fa-paper-plane', link: () => setMainComponent("Sent") },
    { name: "Starred", icon: 'fa-flag', link: () => setMainComponent("Starred") },
    { name: "Bin", icon: 'fa-trash-can', link: () => setMainComponent("Bin") }]

    async function init() {
        let result = await getWnsDomain(address);
        console.log(result);
        if (result != "null") {
            setDomain(result);
        }
    }

    useEffect(() => {
        if (address != null && address != "" && address != undefined) {
            init();
        }
    }, [address])

    return (
        <div className="flex flex-col justify-between items-between " id="sidebar" >
            <div className="">
                <div className="w-[340px] px-4 pb-12">
                    <a href='/' className='flex items-center cursor-pointer'>
                        <img src={Logo} alt="Logo" className="w-8" />
                        <p className='font-bold text-black text-2xl ml-2 whitespace-nowrap'>W3 <span className='text-gray-500 font-semibold tracking-widest'>Mail</span></p>
                    </a>
                </div>
                <GetMappingSidebar paramsArray={items} mainComponent={mainComponent} setMainComponent={setMainComponent} mobileSidebar={mobileSidebar} setMobileSidebar={setMobileSidebar} />
            </div>
            <div className={window.innerWidth < 1025 ? "hidden" : "mb-8 pt-2 flex items-center w-fit  border-t border-t-gray-200"}>

                <div className="cursor-pointer">
                    <div className="flex items-center gap-x-4 px-4 py-4 rounded-3xl cursor-pointer" onClick={() => setAccountModalOpen(true)}>
                        <div className="bg-gray-400 w-11 h-11 flex justify-center items-center" style={{ borderRadius: "16px" }}>
                            <FontAwesomeIcon icon="user-alt" className="text-xl text-white" />
                        </div>
                        <div className=" items-center gap-x-2">
                            {/* <img src={LogoWns} alt="Logo" className="w-4" /> */}
                            <p className="text-gray-500 text-xs pb-1">Manage account</p>

                            <div>
                                <p className="font-semibold text-md text-black">{domain != "" ? domain : shortenaddress(address)}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <AccountModal accountModalOpen={accountModalOpen} setAccountModalOpen={setAccountModalOpen} domain={domain} />
        </div >
    );

}


export default InboxSidebar;


// function sidebarLink(link, setMobileSidebar, mobileSidebar) {
//     console.log(mobileSidebar);
//     if (mobileSidebar == true) {
//         console.log("hmm")
//         link();
//         setMobileSidebar(false);
//     } else {
//         link();
//     }
// }

export const GetMappingSidebar = ({ paramsArray, mainComponent }) => {

    const Map = paramsArray.map((item, index) => {

        return (
            <div key={index}>

                {index == 1 ? (
                    <div style={{ display: "flex" }} className="vertcenter">
                        <div className="border-t border-t-gray-300 p-1 mt-3 w-6/12" />
                    </div>
                ) : (null)}

                <div onClick={() => { item.link() }} className={`cursor-pointer flex items-center px-8 py-4 my-4 rounded-full ${item.name == "Compose" ? "bg-black" : mainComponent == item.name ? "bg-gray-100 border border-gray-200" : "hover:bg-gray-100 border border-white hover:border-gray-200"}`}>

                    <div style={{ width: "15%" }} className="">
                        {item.name == "Inbox" ? (
                            <BsMailbox className="text-black" size={"20px"} />
                        ) : item.name == "Compose" ? (
                            <HiOutlinePencilAlt className="text-white" size={"18px"} />
                        ) : item.name == "Sent" ? (
                            <FiSend className="text-black" size={"18px"} />
                        ) : item.name == "Bin" ? (
                            <FiTrash2 className="text-black" size={"18px"} />
                        ) : (
                            <FontAwesomeIcon icon={['far', item.icon]} className={item.name == "Compose" ? "text-white" : "text-black"} />
                        )}
                    </div>
                    <div style={{ width: "85%" }}>
                        <p className={`${item.name == "Compose" ? "text-white" : "text-black"} text-sm font-semibold`}>{item.name == "Starred" ? "Flagged" : item.name}</p>
                    </div>
                </div>
            </div>
        )
    }
    );

    return Map;
}