import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Checkbox from "react-custom-checkbox";
import { SkeletonInbox } from "./skeleton";
import { getAllEmails, getDecryptedEmails } from "../../../../../../1.resources/2.js/0.global/4.app/getAllEmails";
import { markAsStarred, markInBin } from "../../../../../../1.resources/2.js/0.global/4.app/updateEmail";
import { parseDate } from "../../../../../../1.resources/2.js/0.global/0.smallfunctions/time";
import { shortenaddress } from "../../../../../../1.resources/2.js/0.global/0.smallfunctions/global";
import { getWnsDomain } from "../../../../../../1.resources/2.js/0.global/3.api/callW3Api";
import { colors } from "../../../../../../1.resources/1.css/colors";
import { FiTrash2 } from "react-icons/fi";
import EmptyImg from "../../../../../../1.resources/3.files/images/empty.gif";
import { collection, onSnapshot, orderBy, query, where, limit, startAfter, getDocs, startAt } from "firebase/firestore";
import { db } from "../../../../../../1.resources/2.js/1.app/0.firebase/firebase";
import { useAccount } from 'wagmi'

const InboxInbox = ({ reloadIndex, mainComponent, setMainComponent, setEmailItem, setReloadIndex, setReadDirection }) => {
    const { address } = useAccount()
    const [emailsArray, setEmailsArray] = useState([])
    const [emailsSelected, setEmailsSelected] = useState([])
    const [skeletonLoading, setSkeletonLoading] = useState(true)
    const [page, setPage] = useState(1);
    const [limitValue, setLimitValue] = useState(10);
    const [snapshotEmails, setSnapshotEmails] = useState([]);
    const [lastDocs, setLastDocs] = useState({});
    const [binLoading, setBinLoading] = useState(false);

    useEffect(() => {
        if (mainComponent != "Mail" && mainComponent != "" && mainComponent != "Compose") {
            console.time("inbox");
            setSkeletonLoading(true);
            let q;
            if (mainComponent == "Inbox") {
                q = query(collection(db, "moralis", "events", "Emails"), where("to", "==", address.toLowerCase()), orderBy("blockNumber", "desc"), limit(limitValue));
            } else if (mainComponent == "Sent") {
                q = query(collection(db, "moralis", "events", "Emails"), where("from", "==", address.toLowerCase()), orderBy("blockNumber", "desc"), limit(limitValue));
            } else if (mainComponent == "Starred") {
                q = query(collection(db, "moralis", "events", "Emails"), where("to", "==", address.toLowerCase()), where("toStarred", "==", true), orderBy("blockNumber", "desc"), limit(limitValue));
            } else if (mainComponent == "Bin") {
                q = query(collection(db, "moralis", "events", "Emails"), where("to", "==", address.toLowerCase()), where("toBin", "==", true), orderBy("blockNumber", "desc"), limit(limitValue));
            }
            const find = onSnapshot(q, (querySnapshot) => {
                console.timeEnd("inbox");
                setSkeletonLoading(true);
                let array = []
                querySnapshot.docs.forEach((doc) => {
                    let data = doc.data();
                    data.id = doc.id;
                    array.push(data);
                });
                console.log(array);
                setSnapshotEmails(array);
                setLastDocs({ ...lastDocs, [page]: querySnapshot.docs[querySnapshot.docs.length - 1] });
                console.log("decrypting...");
                getDecryptedEmails(array, mainComponent == "Sent" ? "Sent" : "").then((emails) => {
                    console.log("decrypted");
                    console.log(emails);
                    setEmailsArray([]);
                    setEmailsArray(emails);
                    //setPage(1);
                    setSkeletonLoading(false);
                });

            });
            return () => find();
        }
    }, [mainComponent])


    useEffect(() => {
        if (mainComponent != "Mail" && mainComponent != "" && mainComponent != "Compose" && !skeletonLoading) {
            console.log("page funcyion", page);
            setSkeletonLoading(true);
            console.log("page", page);
            if (page > 1) {
                let lastDoc = lastDocs[page - 1];
                let q;
                if (mainComponent == "Inbox") {
                    q = query(collection(db, "moralis", "events", "Emails"), where("to", "==", address.toLowerCase()), orderBy("blockNumber", "desc"), startAfter(lastDoc), limit(limitValue));
                } else if (mainComponent == "Sent") {
                    q = query(collection(db, "moralis", "events", "Emails"), where("from", "==", address.toLowerCase()), orderBy("blockNumber", "desc"), startAfter(lastDoc), limit(limitValue));
                } else if (mainComponent == "Starred") {
                    q = query(collection(db, "moralis", "events", "Emails"), where("to", "==", address.toLowerCase()), where("toStarred", "==", true), orderBy("blockNumber", "desc"), startAfter(lastDoc), limit(limitValue));
                } else if (mainComponent == "Bin") {
                    q = query(collection(db, "moralis", "events", "Emails"), where("to", "==", address.toLowerCase()), where("toBin", "==", true), orderBy("blockNumber", "desc"), startAfter(lastDoc), limit(limitValue));
                }
                getDocs(q).then((querySnapshot) => {
                    setSkeletonLoading(true);
                    let array = []
                    querySnapshot.forEach((doc) => {
                        let data = doc.data();
                        data.id = doc.id;
                        array.push(data);
                    });
                    console.log(array);
                    setLastDocs({ ...lastDocs, [page]: querySnapshot.docs[querySnapshot.docs.length - 1] });
                    getDecryptedEmails(array).then((emails) => {
                        setEmailsArray([]);
                        setEmailsArray(emails);
                        setSkeletonLoading(false);
                    });
                });
            } else {
                console.log(snapshotEmails);
                setEmailsArray([]);
                setEmailsArray(snapshotEmails);
                setSkeletonLoading(false);
            }
        }
    }, [page])

    async function previousPage() {
        if (page > 1) {
            setPage(page - 1);
        }
    }

    async function nextPage() {
        if (emailsArray.length != 0) {
            setPage(page + 1);
        }
    }

    function selectAllEmail(value) {
        if (value == true) {
            let array = []
            for (let i = 0; i < emailsArray.length; i++) {
                array.push(emailsArray[i].id);
            }
            setEmailsSelected(array);
        } else {
            let array = []
            setEmailsSelected(array);
        }
    }

    async function bin() {
        setBinLoading(true);
        let binPromise = []
        for (let i = 0; i < emailsSelected.length; i++) {
            binPromise.push(markInBin(emailsSelected[i], mainComponent == "Sent" ? "from" : "to"));
        }
        let bin = await Promise.all(binPromise);
        setEmailsSelected([])
        setBinLoading(false);
    }



    return (
        <div className="h-full w-full">
            <div className="z-1 h-full flex-col justify-between items-between">
                <div className="flex-1 w-full" id="upper">
                    <div className={`flex items-center px-5 sm:px-8 py-4 pt-8 border-b border-b-gray-200 w-full`}>
                        <div className="flex justify-between items-center w-full">
                            <div>
                                <p className="font-bold text-4xl">{mainComponent == "Starred" ? "Flagged" : mainComponent}</p>
                                <p className="text-gray-500 text-sm mt-2">Showing {emailsArray.length == 10 ? "10" : emailsArray.length} emails</p>
                            </div>

                            <div className="flex">
                                {emailsSelected.length > 0 ? (
                                    <div onClick={() => bin()} className="">
                                        <div className="hover:bg-gray-100 w-10 h-10 flex justify-center items-center rounded-full">
                                            {binLoading ? (
                                                <FontAwesomeIcon icon={['fas', 'fa-circle-notch']} className={`text-md text-gray-500 animate-spin`} />
                                            ) : (
                                                <FiTrash2 size={"20px"} className="text-gray-500 mb-[1.5px]" />
                                            )}
                                        </div>
                                    </div>
                                ) : (<div />)}

                                <div className="flex justify-end items-center w-[40px]">
                                    <div className="hover:bg-gray-100 w-10 h-10 flex justify-center items-center rounded-full">
                                        <Checkbox
                                            icon={<FontAwesomeIcon icon={['fas', 'fa-check']} style={{ color: 'black', fontSize: "80%" }} />}
                                            checked={false}
                                            onChange={(value, event) => {
                                                selectAllEmail(value);
                                            }}
                                            borderColor={colors.gray500}
                                            style={{ cursor: "pointer" }}
                                            labelStyle={{ marginLeft: 0, userSelect: "none" }}
                                            label=""

                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="w-full h-full">
                        {skeletonLoading ? (
                            <SkeletonInbox />
                        ) : (
                            emailsArray.length === 0 ? (
                                <div className="flex justify-center items-center flex-col mt-28">
                                    <img src={EmptyImg} className="w-96" />
                                    <p className="font-bold text-lg my-3">There are no emails here</p>
                                    <p className="text-sm text-gray-600">Emails you {mainComponent == "Inbox" ? "receive" : mainComponent == "Sent" ? "send" : mainComponent == "Starred" ? "flag" : mainComponent == "Bin" ? "delete" : ""} will appear here</p>
                                </div>
                            ) : (
                                <GetMappingInbox setReadDirection={setReadDirection} emailsSelected={emailsSelected} setEmailsSelected={setEmailsSelected} paramsArray={emailsArray} filter={mainComponent == "Sent" ? "from" : "to"} setMainComponent={setMainComponent} mainComponent={mainComponent} setEmailItem={setEmailItem} setReloadIndex={setReloadIndex} />
                            )
                        )}
                    </div>
                </div>
                <div className="flex justify-end items-center border-t border-t-gray-200 h-full w-full px-8">
                    <div className="flex justify-between items-center w-[100px]">
                        <div className="hover:bg-gray-200 w-10 h-10 flex justify-center items-center rounded-full cursor-pointer" onClick={() => previousPage()}>
                            <FontAwesomeIcon icon={['fas', 'fa-angle-left']} className={`text-md`} />
                        </div>
                        <div className="hover:bg-gray-200 w-10 h-10 flex justify-end sm:justify-center items-center rounded-full cursor-pointer" onClick={() => nextPage()}>
                            <FontAwesomeIcon icon={['fas', 'fa-angle-right']} className={`text-md`} />
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );

}


export default InboxInbox;



const GetMappingInbox = ({ paramsArray, filter, setMainComponent, mainComponent, setEmailItem, setReloadIndex, emailsSelected, setEmailsSelected, setReadDirection = { setReadDirection } }) => {
    const [starLoading, setStarLoading] = useState(null);

    const Map = paramsArray.map((item, index) => {
        let read;
        let starred;
        if (filter == "from") {
            read = item.fromRead;
            starred = item.fromStarred;
        } else {
            read = item.toRead;
            starred = item.toStarred;
        }

        function openEmail(value) {
            setEmailItem(value);
            if (mainComponent == "Inbox" || mainComponent == "Starred" || mainComponent == "Bin") {
                setReadDirection("to");
            } else if (mainComponent == "Sent") {
                setReadDirection("from");
            }
            setMainComponent("Mail")
        }
        async function star(item) {
            setStarLoading(item.id);
            await markAsStarred(item.id, filter);
            setStarLoading(null);
        }
        function selectEmail(value, itemValue) {
            if (value == true) {
                if (emailsSelected.includes(itemValue.id)) { } else {
                    let array = [...emailsSelected, itemValue.id];
                    setEmailsSelected(array);
                }
            } else {
                let array = [...emailsSelected];
                if (array.includes(itemValue.id)) {
                    array = array.filter(function (item) {
                        return item !== itemValue.id
                    })
                    setEmailsSelected(array);
                }
            }
        }
        return (
            <div key={index} className="">

                <div className={`flex items-center px-8 py-4 border-b border-b-gray-200 ${read ? "" : ""} hover:bg-gray-100 cursor-pointer`}>

                    <div className="w-[40px] flex justify-start items-center" onClick={() => openEmail(item)}>
                        <FontAwesomeIcon icon={['fas', 'fa-circle']} className={`${read ? "text-gray-400" : "text-main"} text-xs`} />
                    </div>

                    <div className="w-full">
                        <div className="flex justify-between items-center w-full" >
                            <div className="overflow-hidden w-full pr-10" onClick={() => openEmail(item)}>
                                <GetDomain item={item} read={read} />
                                <p className="text-sm line-clamp-1">{item.email.subject}</p>
                                <GetBody item={item} />
                            </div>

                            <div className="flex flex-col justify-center items-center w-[50px] sm:w-[100px]">
                                <div className="flex justify-center items-center w-full">
                                    <GetTime item={item} />
                                </div>
                                <div className={`mt-2 flex items-center w-full ${mainComponent != "Sent" ? "justify-end sm:justify-between" : "justify-end"}`}>
                                    {mainComponent != "Sent" ? (
                                        <div className="hover:bg-gray-200 w-10 h-10 hidden sm:flex justify-center items-center rounded-full " onClick={() => star(item)}>
                                            {starLoading == item.id ? (
                                                <FontAwesomeIcon icon={['fas', 'fa-circle-notch']} className={`text-md text-gray-500 animate-spin`} />
                                            ) : (
                                                <FontAwesomeIcon icon={[starred ? 'fas' : 'far', 'fa-flag']} className={`text-md ${starred ? "text-main" : "text-gray-500"}`} onClick={() => star(item)} />
                                            )}
                                        </div>
                                    ) : (null)}

                                    <div className="hover:bg-gray-200 w-10 h-10 flex justify-end sm:justify-center items-center rounded-full cursor-pointer">
                                        <Checkbox
                                            icon={<FontAwesomeIcon icon={['fas', 'fa-check']} size={"xs"} />}
                                            checked={emailsSelected.includes(item.id) ? true : false}
                                            onChange={(value, event) => {
                                                selectEmail(value, item);
                                            }}
                                            borderColor={{}}
                                            style={{ cursor: "pointer", borderColor: colors.gray500 }}
                                            labelStyle={{ marginLeft: 0, userSelect: "none" }}
                                            label=""
                                        />
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
    }
    );

    return Map;
}


const GetBody = ({ item }) => {
    const [value, setValue] = useState("")

    async function init() {
        let doc;
        try {
            doc = new DOMParser().parseFromString(JSON.parse(item?.email?.body), "text/html");
        } catch (e) {
            doc = new DOMParser().parseFromString(item?.email?.body, "text/html");

        }

        const text = [];
        doc.body.childNodes.forEach(function check(child) {
            if (child.nodeType === Node.TEXT_NODE) {
                text.push(child.nodeValue.trim());
            }
            child.childNodes.forEach(check);
        });
        setValue(text.join(' ').slice(0, 200));
    }

    useEffect(() => {
        init();
    }, [])
    return (
        <p className="line-clamp-1 text-gray-500 text-sm">{value}</p>
    );
}


export const GetTime = ({ item, size }) => {
    const [value, setValue] = useState([])

    async function init() {
        let timestamp;
        if (item.blockTimestamp != null) {
            timestamp = item.blockTimestamp * 1000;
        } else {
            let result = await (await fetch(`https://deep-index.moralis.io/api/v2/block/${item.blockNumber}?chain=matic`, { headers: { Accept: "application/json", "X-Api-Key": "qh9GURBnTigYGpYJ51Xt0GeDBd7aSAseXafbWW9SLNSiwzsM0HtK1Iat3SKYkl2j" } })).json();
            timestamp = result.timestamp;
        }
        let date = new Date(timestamp);
        let parsed = await parseDate(date);
        setValue(parsed);
    }

    useEffect(() => {
        init();
    }, [])
    return (
        <p className={` ${size != null ? "text-sm" : "text-xs"} text-gray-500 whitespace-nowrap`}>{value}</p>
    );
}

const GetDomain = ({ item }) => {
    const [value, setValue] = useState([])

    async function init() {
        setValue(shortenaddress(item.from));
        let result = await getWnsDomain(item.from);
        if (result != "null") {
            setValue(result);
        } else {
            setValue(shortenaddress(item.from));
        }
    }

    useEffect(() => {
        init();
    }, [])
    return (
        <div>
            <p className="font-semibold text-md">{value}</p>
        </div >
    );
}