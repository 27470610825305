import { ConnectButton } from '@rainbow-me/rainbowkit';
import { delay, shortenaddress } from '../../../1.resources/2.js/0.global/0.smallfunctions/global';
import UserImg from "../../../1.resources/3.files/images/user.png";
import { Dialog, Transition } from '@headlessui/react'
import { Fragment, useEffect, useState } from 'react'
import { useAccount } from 'wagmi'
import { useBalance } from 'wagmi'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useDisconnect } from 'wagmi'
import { watchAccount } from '@wagmi/core'
import CloudContracts from '../../../1.resources/2.js/0.global/2.contracts/cloudContracts';
import { getWnsDomain } from '../../../1.resources/2.js/0.global/3.api/callW3Api';

export const ConnectWallet = ({ }) => {
    const [accountModalOpen, setAccountModalOpen] = useState(false);
    const { address, isConnecting, isDisconnected, isReconnecting } = useAccount()
    const [domain, setDomain] = useState("");
    const [currentAddress, setCurrentAddress] = useState("");
    const { disconnect } = useDisconnect()

    async function init() {
        let result = await getWnsDomain(address);
        console.log(result);
        if (result != "null") {
            setDomain(result);
        }
    }

    useEffect(() => {
        if (address != null && address != "" && address != undefined) {
            init();
        }
    }, [address])

    return (
        <div>
            <ConnectButton.Custom >
                {({
                    account,
                    chain,
                    openAccountModal,
                    openChainModal,
                    openConnectModal,
                    authenticationStatus,
                    mounted,
                }) => {
                    // Note: If your app doesn't use authentication, you
                    // can remove all 'authenticationStatus' checks
                    const ready = mounted && authenticationStatus !== 'loading';
                    const connected =
                        ready &&
                        account &&
                        chain &&
                        (!authenticationStatus ||
                            authenticationStatus === 'authenticated');

                    return (
                        <div
                            {...(!ready && {
                                'aria-hidden': true,
                                'style': {
                                    opacity: 0,
                                    pointerEvents: 'none',
                                    userSelect: 'none',
                                },
                            })}
                        >
                            {(() => {
                                if (!connected) {
                                    return (
                                        <button onClick={openConnectModal} type="button" className='bg-black text-white rounded-full p-3 px-4 font-bold text-sm'>
                                            Connect Wallet
                                        </button>
                                    );
                                } else {
                                    return (
                                        <div style={{ display: 'flex', gap: 0 }}>

                                            <button onClick={() => setAccountModalOpen(true)} type="button" className='flex items-center gap-2 font-bold text-sm bg-white rounded-full shadowDiv'>
                                                <div className="bg-gray-400 w-10 h-10 flex justify-center items-center" style={{ borderRadius: "12px" }}>
                                                    <FontAwesomeIcon icon="user-alt" className="text-md text-white" />
                                                </div>
                                            </button>
                                        </div>
                                    );
                                }

                                // if (chain.unsupported) {
                                //     return (
                                //         <button onClick={openChainModal} type="button" className='font-bold text-sm bg-[#F3A423] px-[15px] py-[10px] rounded-full'>
                                //             Switch network
                                //         </button>
                                //     );
                                // }


                            })()}
                        </div>
                    );
                }}

            </ConnectButton.Custom>
            <AccountModal accountModalOpen={accountModalOpen} setAccountModalOpen={setAccountModalOpen} domain={domain} />
        </div>
    );
};


export function AccountModal({ accountModalOpen, setAccountModalOpen, domain }) {
    const { address, isConnecting, isDisconnected } = useAccount()
    const { data, isError, isLoading } = useBalance({ address: address })
    const { disconnect } = useDisconnect()


    function closeModal() {
        setAccountModalOpen(false)
    }

    async function disconnectWallet() {
        localStorage.clear();
        sessionStorage.clear();
        disconnect();
        setAccountModalOpen(false);
        window.location = "/";
    }


    return (
        <>
            <Transition appear show={accountModalOpen} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={closeModal}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>

                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex min-h-full items-center justify-center p-4 text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                    <div className='flex justify-end'>
                                        <div className='w-6 h-6 bg-[#E6E6E6] rounded-full flex justify-center items-center cursor-pointer' onClick={() => setAccountModalOpen(false)}>
                                            <FontAwesomeIcon icon={['fas', 'fa-xmark']} className="" style={{ color: '#5E6363', fontSize: "100%" }} />
                                        </div>
                                    </div>
                                    <div className='px-4'>
                                        <div className='flex flex-col items-start -mt-4'>
                                            {/* <img src={UserImg} className='w-[80px] h-[80px] rounded-full' /> */}
                                            <div className="bg-gray-400 w-20 h-20 flex justify-center items-center" style={{ borderRadius: "24px" }}>
                                                <FontAwesomeIcon icon="user-alt" className="text-3xl text-white" />
                                            </div>
                                            <p className='pt-6 font-bold text-2xl'>{address != null ? domain == "" ? shortenaddress(address) : domain : ""}</p>
                                            <p className='pt-0 text-sm text-gray-500'>{"Balance: " + parseFloat(data?.formatted).toFixed(3)} {data?.symbol}</p>

                                            <div className='mt-8'>
                                                <div>
                                                    <p className='font-bold text-sm'>Address</p>
                                                </div>
                                                <div>
                                                    <p className='text-sm text-gray-500'>{address}</p>
                                                </div>
                                            </div>

                                            <div className='mt-8'>
                                                <div>
                                                    <p className='font-bold text-sm'>Username</p>
                                                </div>
                                                <div>
                                                    <p className='text-sm text-gray-500'>{domain != "" ? domain : "Not registered"}</p>
                                                </div>
                                            </div>

                                        </div>
                                        <div className='w-full pt-10' >
                                            <a className='w-full flex justify-center items-center bg-black m-1 py-4 rounded-full cursor-pointer gap-x-3' href='https://domains.w3.one/' target={"_blank"}>
                                                <FontAwesomeIcon icon={["fas", "fa-user-alt"]} style={{ color: '#fff', fontSize: "100%" }} />
                                                <p className='font-bold text-sm text-white'>Manage username</p>
                                            </a>
                                            <div className='w-full flex justify-center items-center bg-white m-1 mt-4 py-2 rounded-full cursor-pointer gap-x-3' onClick={() => disconnectWallet()}>
                                                <FontAwesomeIcon icon={['fas', 'fa-arrow-right-from-bracket']} style={{ color: '#000', fontSize: "100%" }} />
                                                <p className='font-bold text-sm text-black'>Disconnect</p>
                                            </div>
                                        </div>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}