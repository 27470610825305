import { callW3Api } from "../3.api/callW3Api";
import { getJwtToken } from "./getProxy";

export async function markAsRead(id, param) {
    console.log("hkj")
    let jwtToken = await getJwtToken();
    let result = await callW3Api("/email/update/read", { id: id, param: param, jwtToken: jwtToken });
    console.log(result);
}

export async function markAsStarred(id, param) {
    let jwtToken = await getJwtToken();
    let result = await callW3Api("/email/update/star", { id: id, param: param, jwtToken: jwtToken });
    console.log(result);
}

export async function markInBin(id, param) {
    let jwtToken = await getJwtToken();
    let result = await callW3Api("/email/update/bin", { id: id, param: param, jwtToken: jwtToken });
    console.log("bin:", result);
    return result
}