import React from 'react';
import Logo from "../../../1.resources/3.files/logo/w3mail6.png";
import Banner from './banner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ConnectWallet } from '../wallet/connectWallet';

const Header = () => {

    return (
        <div style={{ display: "unset" }}>

            <div className="flex lg:hidden items-center px-5 pt-5">

                <a href='/' className='flex items-center w-6/12 cursor-pointer'>
                    <img src={Logo} alt="Logo" className="w-8" />
                    <p className='font-bold text-2xl ml-2 blackspace-nowrap text-black'>W3 <span className='text-black font-semibold tracking-widest'>Mail</span></p>
                </a>
                <div className="w-6/12 flex justify-end">
                    <div onClick={() => window.location = "/inbox"} className='bg-black rounded-full p-3 px-4 w-fit'>
                        <p className='text-white font-bold text-sm'>Go to Inbox</p>
                    </div>
                </div>
            </div>


            <div className='sticky top-0 z-50'>
                <div className='hidden lg:block px-40 py-1 px-10' id="header">
                    <header className="py-2 flex justify-between items-center px-5 md:px-0">
                        <a href='/' className='flex items-center w-full cursor-pointer'>
                            <img src={Logo} alt="Logo" className="w-8 h-8" />
                            <p className='font-bold text-2xl ml-2 blackspace-nowrap text-black'>W3 <span className='text-neutral-500 font-bold tracking-widest'>Mail</span></p>
                        </a>
                        <div id="headerSearch" className='w-full'>

                        </div>

                        <div className="flex items-center justify-end w-full gap-x-8">
                            <a href='#use-cases' className='mt-0'>
                                <p className='text-black font-bold text-md'>Explore</p>
                            </a>
                            <a href='https://docs.mail.w3.one/' target={"_blank"}>
                                <p className='text-black font-bold text-md'>Docs</p>
                            </a>
                            <a href='/inbox' className='bg-black rounded-full p-3 px-4'>
                                <p className='text-white font-bold text-sm'>Go to Inbox</p>
                            </a>
                        </div>
                    </header>
                </div>
                {/* <Banner /> */}

            </div >
        </div >
    )
}

export default Header;
