import React, { useContext } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Img1 from "../../../1.resources/3.files/images/blockchain3.png";
import Rainbow from "../../../1.resources/3.files/images/rainbow.png";
import Wallets from "../../../1.resources/3.files/images/wallets.png";
import Img4 from "../../../1.resources/3.files/images/open5.png";
import { ScrollBox } from "../../0.global/scrollbox/scrollbox";
import WnsLogo from "../../../1.resources/3.files/logo/logo2.png";

function OpenSource() {

    let item = { "title": "Secured by Blockchain", "subheading": "Polygon (MATIC)", "image": Img1, "body": "The Blockchain provides an immutable layer of security by leveraging the public ledger systems that are not maintained by a central authority and can be independently verified by anyone in the world, making them error-proof and highly secure." }

    return (
        <div className="flex justify-center">
            <div className="">


                <ScrollBox elements={
                    <div className="w-full lg:flex items-center lg:gap-x-8">
                        <div className="lg:w-6/12 rounded-3xl p-16  bg-gray-100">
                            <p className="text-5xl font-bold ">Sign in with</p>
                            <p className="text-5xl font-bold text-[#000] mt-2">Ethereum</p>

                            <div className="flex items-center gap-x-2 mt-8">
                                <img src={"https://www.iconarchive.com/download/i109534/cjdowner/cryptocurrency-flat/Ethereum-ETH.1024.png"} className="w-9 h-9 bg-[#627EEA]" style={{ borderRadius: "13px" }} />
                                <span className="text-sm font-semibold text-black ">
                                    Connect using wallets <br />
                                    on the Ethereum network
                                </span>
                            </div>
                            <div className="mt-8">
                                {/* <img src={Wallets} /> */}
                                <div className="mt-8">
                                    <Logos />
                                </div>
                                <div className="mt-8">
                                    <Logos />
                                </div>
                                <div className="mt-8">
                                    <Logos />
                                </div>
                            </div>
                        </div>
                        <div className="lg:w-6/12 rounded-3xl p-16 bg-gray-100 mt-20 lg:mt-0">
                            <div className="mb-8 rounded-3xl py-4">
                                <Username username={"michael.web3"} />
                                <div className="mt-4">
                                    <Username username={"katherine.web3"} />
                                </div>
                                <div className="mt-4">
                                    <Username username={"george.web3"} />
                                </div>
                                <div className="mt-4">
                                    <Username username={"john.web3"} />
                                </div>
                            </div>
                            <p className="text-5xl font-bold mt-8 text-black">Connect using<br /> <span className="">your <br />web3 <br />username</span></p>
                            <div className="flex items-center gap-x-2 mt-8">
                                <img src={WnsLogo} className="w-9 h-9 bg-black" style={{ borderRadius: "13px" }} />
                                <span className="text-sm font-semibold text-black ">
                                    Send email to people <br />
                                    using their web3 username
                                </span>
                            </div>
                        </div>
                    </div>
                } id="half" />

                <ScrollBox elements={
                    <div id="mainhead" className="bg-gray-100 rounded-3xl mt-16 px-20 py-20" >
                        <div className="justify-center items-center block xl:flex gap-x-10">
                            <div className="w-full xl:w-6/12">
                                <div>
                                    <p id="heroheading" className="font-bold text-xl md:text-4xl text-start md:text-start text-black">
                                        Build on the <br /><span className="text-[#000] text-6xl">protocol</span>
                                    </p>

                                    <p id="herosub" className="text-xl mt-4 text-black">
                                        The architecture of W3 Mail has been made open source to enable further development and improvement, with the goal of facilitating interoperability across various email clients.
                                    </p>
                                    <br />
                                    <a href="https://docs.mail.w3.one/" target={"_blank"}>Read our docs for more info</a>
                                    <br />
                                    <br />
                                    <div className="flex items-center gap-x-2">
                                        <div className="w-9 h-9 bg-[#2e8875] flex justify-center items-center" style={{ borderRadius: "13px" }}>
                                            <FontAwesomeIcon icon={["fas", "fa-gear"]} className="text-md text-white" />
                                        </div>

                                        <span className="text-sm font-semibold text-black">
                                            W3 Mail Protocol <br />
                                            offers interoperability
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="px-0 w-full xl:w-6/12">
                                <img src={Img4} className="rounded-3xl w-full h-full object-cover" />
                            </div>
                        </div>
                    </div>
                } id="build" />

            </div >
        </div>
    );
}

export default OpenSource;


const Logos = () => {
    let items = ["https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/MetaMask_Fox.svg/1200px-MetaMask_Fox.svg.png",
        "https://avatars.githubusercontent.com/u/18060234?s=280&v=4",
        "https://example.walletconnect.org/favicon.ico",
        "https://play-lh.googleusercontent.com/fMUvmUmIpIDoZGTACYohbY3DE7-24GFkQ21WjVHxa57qluzWrr7khkycE8cz_juhew=w240-h480-rw"]
    return (
        <div className="flex items-center justify-between">
            {shuffle(items).map((item, index) => (
                <div key={index} className="mt-2">
                    <img src={item} className="w-16 h-16 bg-white rounded-3xl" />
                </div>
            ))}
        </div>
    )
}

const Username = ({ username }) => {
    return (
        <div className="h-6 rounded-full flex items-center">
            <p className="text-3xl font-bold text-neutral-400">{username}</p>
        </div>
    )
}

function shuffle(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}