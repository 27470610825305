import React, { useContext } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Img1 from "../../../1.resources/3.files/images/blockchain5.png";
import Img2 from "../../../1.resources/3.files/images/ipfs4.png";
import Img3 from "../../../1.resources/3.files/images/encryption3.png";
import Img4 from "../../../1.resources/3.files/images/open4.png";
import { ScrollBox } from "../../0.global/scrollbox/scrollbox";
import OpenSource from "./opensource";

function UseCases() {

    let items = [
        { "title": "Secured by the Blockchain", "subheading": "Polygon (MATIC)", "image": Img1, "body": "The utilization of the Blockchain technology enhances the security of our platform, as it adds an immutable layer that leverages public ledger systems, which are not subject to central authority control. This feature ensures that the system is error-proof and highly secure, while being independently verifiable by any individual across the globe." },
        { "title": "Stored on a distributed network", "subheading": "InterPlanetary File System (IPFS)", "image": Img2, "body": "Our platform utilizes the Interplanetary File System (IPFS), a distributed file storage protocol that enables computers worldwide to store and serve files within a vast peer-to-peer network. All emails sent through our platform are securely stored on this distributed network, ensuring redundancy, availability, and accessibility from any location worldwide." },
        { "title": "End-to-end encrypted", "subheading": "Ethereum address to address", "image": Img3, "body": "To ensure security and confidentiality, all emails sent through our platform are encrypted on the sender's device, transmitted to the Blockchain, and subsequently stored on the IPFS network. Upon arrival at the receiver's device, the email is decrypted, and throughout its journey, it remains safeguarded from unauthorized access or tampering." },
        // { "title": "Open-source", "subheading": "Build on the protocol", "image": Img3, "body": "Want to build your own email service on top of W3 Mail? The architecture behind W3 Mail is open-sourced to be built upon, and improved to allow interoperability between different emailing clients. More information on the architecture of W3 Mail will be found in our docs.        " }

    ]

    return (
        <div className="px-5 md:px-20 lg:px-40 py-20 lg:pt-20" id="use-cases">
            {/* <div className="md:text-center">
                <p className="text-4xl font-bold text-black">Use Cases</p>
                <p className="text-lg text-gray-500 pt-2">Implementations of Web3 domains</p>
            </div> */}
            <p className="text-5xl md:text-6xl text-gray-300 font-bold text-start mt-10 md:mt-4">the most <span className="text-black">secure</span> email platform</p>
            <p className="text-5xl md:text-6xl text-gray-300 font-bold text-start mt-10 md:mt-4">the most <span className="text-black">private</span> email platform</p>
            <p className="text-5xl md:text-6xl text-gray-300 font-bold text-start mt-10 md:mt-4">the most <span className="text-black">transparent</span> email platform</p>
            <p className="text-5xl md:text-6xl text-gray-300 font-bold text-start mt-10 md:mt-4">the most <span className="text-black">encrypted</span> email platform</p>
            <p className="text-5xl md:text-6xl text-gray-300 font-bold text-start mt-10 md:mt-4">the most <span className="text-black">decentralised</span> email platform</p>
            <p className="text-5xl md:text-6xl text-gray-300 font-bold text-start mt-10 md:mt-4">the most <span className="text-black">web3</span> email platform</p>
            <div className="flex justify-center items-center">
                <div className="lg:w-[1024px] mt-10">
                    {items.map((item, index) => (
                        <div key={index}>
                            <ScrollBox elements={
                                <div className="flex justify-center items-center">
                                    <div id="mainhead" className="py-5 md:py-20 mt-10 bg-gray-100 rounded-3xl px-20" >
                                        {/* <FontAwesomeIcon icon={["far", "fa-circle-check"]} className="text-4xl md:text-6xl text-main" /> */}

                                        <div className="justify-center items-center block xl:flex">
                                            <div className="w-full xl:w-6/12 pr-10">
                                                <div>
                                                    <p id="heroheading" className="font-bold text-4xl md:text-5xl text-start md:text-start text-black">
                                                        {index == 0 ? (
                                                            <span>Secured by the <br /><span className="">Blockchain</span></span>
                                                        ) : (index == 1) ? (
                                                            <span>Stored on a <br /><span className="">distributed</span><br />network</span>
                                                        ) : (
                                                            <span>End-to-end <br /><span className="">encrypted</span></span>
                                                        )}
                                                    </p>

                                                    <p id="herosub" className="text-xl mt-4 text-black">
                                                        {item.body}
                                                    </p>
                                                    <br />
                                                    <br />
                                                    <div className="flex items-center gap-x-2">
                                                        {index == 0 ? (
                                                            <img src={"https://cdn.iconscout.com/icon/free/png-256/polygon-token-4086724-3379854.png"} className="w-9 h-9 bg-[#8247E5]" style={{ borderRadius: "13px" }} />
                                                        ) : index == 1 ? (
                                                            <img src={"https://docs.ipfs.tech/images/ipfs-logo.svg"} className="w-9 h-9 bg-[#fff] p-1" style={{ borderRadius: "13px" }} />
                                                        ) : (null)}



                                                        {index == 2 ? (
                                                            <div className="w-9 h-9 bg-[#F2A840] flex justify-center items-center" style={{ borderRadius: "13px" }}>
                                                                <FontAwesomeIcon icon={["fas", "fa-lock"]} className="text-md text-white" />
                                                            </div>
                                                        ) : (null)}


                                                        {index == 0 ?
                                                            (
                                                                <span className="text-sm font-semibold text-black">
                                                                    Emails are secured by <br />
                                                                    the Polygon Blockchain
                                                                </span>
                                                            ) : index == 1 ? (
                                                                <span className="text-sm font-semibold text-black">
                                                                    Emails are stored on the<br />
                                                                    Interplanetary File System (IPFS)
                                                                </span>
                                                            ) : (
                                                                <span className="text-sm font-semibold text-black">
                                                                    All emails are protected by <br />
                                                                    end-to-end encryption
                                                                </span>
                                                            )}

                                                    </div>
                                                </div>
                                            </div>
                                            <div className="px-0 w-full xl:w-6/12">
                                                <img src={item.image} className="rounded-3xl w-full h-full object-cover" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            } id={"hero-boxes" + index} />
                        </div>
                    ))}
                    <div className="pt-20">
                        <OpenSource />
                    </div>
                </div>
            </div>
        </div >


    );
}

export default UseCases;

