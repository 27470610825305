import React from 'react';
import HeroImg from "../../../1.resources/3.files/images/hero90.png";
import Logo from "../../../1.resources/3.files/logo/w3mail.png";
import Banner from '../../0.global/header/banner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ScrollBox } from '../../0.global/scrollbox/scrollbox';

const Questions = ({ }) => {


    let array = [
        { question: "What is W3 Mail?", answer: "W3 Mail is a secure mailing protocol that uses Blockchain technology and the Interplanetary File System to provide a highly secure email platform. Emails are securely stored on the distributed IPFS network, and are encrypted using end-to-end encryption. The platform ensures that only the intended recipient can access the message, making it a reliable and secure email communication option for businesses and individuals.        " },
        { question: "How does it work?", answer: "The platform uses end-to-end encryption, which means that all emails are first encrypted on the sender's device before being transmitted. The encrypted email is then stored on the distributed IPFS network, ensuring redundancy, availability, and accessibility from any location worldwide. The email along with the hash of the encrypted email content is then transmitted to the Blockchain, where a record of the transaction is stored.", answer2: "Upon arrival at the recipient's device, the email is decrypted, ensuring that only the intended recipient can access the message. This process ensures that all messages sent through W3 Mail are highly secure and private." },
        { question: "What is the difference between W3 Mail and Web2 email platforms?", answer: "The main difference between W3 Mail and other web2 email clients lies in their approach to security and privacy. While web2 clients relies on centralized servers and third-party providers to store and transmit emails, W3 Mail utilizes blockchain technology and a distributed network of computers to provide an unparalleled level of security and privacy. W3 Mail uses end-to-end encryption to ensure that emails are secure from unauthorized access or tampering. Furthermore, W3 Mail does not collect or store any user data, ensuring that all communications remain private and anonymous." },
        { question: "Can I send emails to Web2 clients like Gmail and Outlook?", answer: "No, you cannot send emails to Web2 clients as W3 Mail and Web2 clients are built on different architectures." },
        { question: "What is the difference between W3 Mail and W3 Mail protocol?", answer: "The W3 Mail protocol is a secure mailing protocol that uses Blockchain technology to provide a highly secure email platform. W3 Mail is a secure email client that uses the W3 Mail protocol to provide a highly secure email platform. Though the name is identical, W3 Mail is a client built upon the W3 Mail protocol. Other email clients can be also built upon the W3 Mail protocol." },
        { question: "Can I build my own client on the W3 Mail protocol?", answer: "Yes, you can build your own client on the W3 Mail protocol. The W3 Mail protocol is open-source and free to use. You can find the source code on PolygonScan. We will post instructions on creating an email client in our docs." },
        { question: "Does it cost gas to send emails?", answer: "No, it does not cost gas to send emails. The W3 Mail protocol is free to use. We handle all gas costs on your behalf" },
        { question: "How can I send emails to other users?", answer: "You can send emails to other users who are registered on W3 Mail using their Ethereum address or their Web3 username from W3 Name Service (WNS)" }
    ]

    return (
        <div className="flex justify-center items-center pb-40 px-5 md:px-16 pt-20 md:pt-0" id="questions">
            <div className='w-[768px]' id="" >
                <p className='font-bold text-6xl text-center'>Questions?</p>

                <div className='mt-8'>
                    {array.map((item, index) => (
                        <div key={index}>
                            <Mapping item={item} index={index} />
                        </div>
                    )
                    )}
                </div>
            </div>
        </div>
    )
}

export default Questions;

const Mapping = ({ item }) => {
    const [isOpen, setIsOpen] = React.useState(false);
    return (
        <div>
            <div className="p-4 py-6 border-b border-b-neutral-300">
                <div className="flex justify-between items-center cursor-pointer hover:text-main" onClick={() => setIsOpen(!isOpen)}>
                    <p className="font-semibold text-2xl">{item.question}</p>
                    <FontAwesomeIcon icon={isOpen ? "minus" : "plus"} className="" />
                </div>
                <div className={`mt-4 text-xl text-neutral-500 ${isOpen ? "block" : "hidden"}`}>
                    {item.answer}
                    <br />
                    <br />
                    {item.answer2}
                </div>


            </div>

        </div>
    )
}