
export async function parseEmail(body) {
    console.log(body);
    let doc;
    try {
        doc = new DOMParser().parseFromString(JSON.parse(body), "text/html");
    } catch (e) {
        doc = new DOMParser().parseFromString(body, "text/html");

    }

    var scripts = doc.getElementsByTagName('script'); // Live NodeList of your anchor elements
    for (var i = 0; i < scripts.length; i++) {
        var script = scripts[i];
        script.remove();
    }

    return doc.documentElement.innerHTML
}