import axios, * as others from 'axios';


/* export async function callW3Api(urlpath, body) {
    let url = process.env.REACT_APP_API_URL + urlpath;
    let response = await (await fetch(url, {
        method: 'POST',
        body: JSON.stringify(body)
    })).json();

    console.log(response);
    return response.value;
} */

export async function callW3Api(urlpath, body) {
    let url = process.env.REACT_APP_API_URL + urlpath;
    let response = await axios({
        method: 'post',
        url: url,
        data: body
    });

    return response.data.value;
}


export async function getWnsDomain(address) {
    let result = await (await fetch("https://resolver.wnsdomains.io/resolve/address=" + address)).json()
    return result.value;
}

export async function getWnsAddress(domain) {
    let result = await (await fetch("https://resolver.wnsdomains.io/resolve/name=" + domain)).json()
    return result.value;
}