import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Checkbox from "react-custom-checkbox";
import Web3 from "web3";
import Modal from "react-modal";
import "./styles.css";
import { ethers } from "ethers";
import { callW3Api, getWnsAddress, getWnsDomain } from "../../../../../../1.resources/2.js/0.global/3.api/callW3Api";
import CloudContracts from "../../../../../../1.resources/2.js/0.global/2.contracts/cloudContracts";
import { shortenaddress } from "../../../../../../1.resources/2.js/0.global/0.smallfunctions/global";
import { useAuth } from "../../../../../../3.components/1.wrappers/1.auth";
import { signMessage } from '@wagmi/core'
import { encryptEmail } from "../../../../../../1.resources/2.js/0.global/4.app/encryptEmail";
import { getCloudProvider } from "../../../../../../1.resources/2.js/0.global/2.contracts/cloudProvider";
import { sendPrepend } from "../../../../../../1.resources/2.js/0.global/0.smallfunctions/prepends";
import { Variables } from "../../../../../../1.resources/2.js/0.global/2.contracts/variables";
import RichTextEditor from 'react-rte';
Modal.setAppElement("#root");

class InboxCompose extends React.Component {

    componentDidMount() {
        let target = document.getElementsByClassName("EditorToolbar__root___3_Aqz")[0];
        document.getElementById("composeButtons").append(target);
        // document.getElementById("emailBody").style.minHeight = window.innerHeight - document.getElementById("header").scrollHeight - 420 + "px";
        document.getElementById("emailText").style.minHeight = window.innerHeight - 500 + "px";
        document.getElementById("emailHtml").style.minHeight = window.innerHeight - 500 + "px";

    }
    render() {
        return (
            <div>
                <ComposeDiv snackbar={this.props.snackbar} setMainComponent={this.props.setMainComponent} paramTo={this.props.paramTo} paramBody={this.props.paramBody} />
            </div>
        );
    }
}


export default InboxCompose;


const ComposeDiv = ({ snackbar, paramTo, paramBody, setMainComponent }) => {
    const { accountInfo } = useAuth();
    const [minimize, setMinimize] = useState(false);
    const [toAddress, setToAddress] = useState("");
    const [toDomain, setToDomain] = useState("");
    const [registeredError, setRegisteredError] = useState(false);
    const [toLoading, setToLoading] = useState(false);
    const [toValue, setToValue] = useState("");
    const [emailSubject, setEmailSubject] = useState("");
    const [emailBody, setEmailBody] = useState("");
    const [emailAttachments, setEmailAttachments] = useState([]);
    const [buttonLoading, setButtonLoading] = useState(false);
    const [signInfo, setSignInfo] = useState(false);
    const [copied, setCopied] = useState(false)
    const [htmlValue, setHtmlValue] = useState("");


    function copy(value) {
        navigator.clipboard.writeText(value);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    }

    useEffect(() => {
        if (paramTo != null) {
            setToAddress(paramTo)
        }
    }, [paramTo])

    let css = {
        maximized: {
            background: "#fff", boxShadow: "rgba(17, 17, 26, 0.1) 0px 0px 16px", height: "100%", width: "100%"
        },
        notMaximized: {
            position: "fixed", bottom: "0%", right: "7.5%", padding: "0%", width: minimize ? "400px" : "600px", height: minimize ? "auto" : "600px", background: "#fff", boxShadow: "rgba(17, 17, 26, 0.1) 0px 0px 16px"
        }
    }

    async function checkIfRegistered(address) {
        let registerd = await CloudContracts("pol", process.env.REACT_APP_NETWORK, "full").w3MailContract.getProxyKey(address);
        if (registerd != "") {
            return true
        } else {
            return false;
        }
    }

    async function parseAddress(value) {
        setToValue(value);
        setToLoading(true);
        setToAddress("");
        setToDomain("");
        setRegisteredError(false);
        if (value.includes(".web3")) {
            let address = await getWnsAddress(value.toLowerCase());
            console.log(address);
            if (address != "null") {
                let isRegistered = await checkIfRegistered(address);
                if (isRegistered == true) {
                    setToDomain(value.toLowerCase());
                    setToAddress(address);
                } else {
                    setRegisteredError(true);
                }
            }
        } else {
            let isAddress = ethers.utils.isAddress(value);
            console.log(isAddress);
            if (isAddress == true) {
                let isRegistered = await checkIfRegistered(value);
                if (isRegistered == true) {
                    let domain = await getWnsDomain(value.toLowerCase());
                    console.log(domain);
                    if (domain != "null") {
                        setToDomain(domain.toLowerCase());
                    }
                    setToAddress(value);
                } else {
                    setRegisteredError(true);
                }
            }
        }
        setToLoading(false);
    }

    function clearInput() {
        setToValue("");
        setToAddress("");
        setToDomain("");
        setRegisteredError(false);
    }

    async function sendEmail() {
        if (ethers.utils.isAddress(toAddress) == true) {
            setButtonLoading(true);
            let from = accountInfo.address;
            console.log(from);
            try {
                let email = {
                    subject: emailSubject,
                    body: htmlValue,
                    contentType: "html",
                    attachments: emailAttachments
                }
                console.log(email);
                let { encryptedEmail, fromEncryptedPassword, toEncryptedPassword } = await encryptEmail(email, from, toAddress);

                let ipfsJson = {
                    fromEncryptedPassword: fromEncryptedPassword,
                    toEncryptedPassword: toEncryptedPassword,
                    encryptEmail: encryptedEmail
                }
                let cid = await callW3Api("/ipfs/add", { content: JSON.stringify(ipfsJson) });


                setSignInfo(true);
                let signature = await signMessageWithParam(sendPrepend, cid, from);
                console.log(signature);
                setSignInfo(false);
                let sendJson = {
                    fromAddress: from,
                    toAddress: toAddress,
                    emailHash: cid,
                    signature: signature
                }
                let hash = await callW3Api("/email/send", sendJson);
                console.log(hash);
                snackbar.setSnackbarActive(true);
                snackbar.setSnackbarMessage("Transaction submitted");
                snackbar.setSnackbarIcon("fa-circle-notch");
                snackbar.setSnackbarSpin(true);
                snackbar.setSnackbarLink("https://polygonscan.com/tx/" + hash);
                clearInput();
                setEmailBody("");
                setEmailSubject("");
                setEmailAttachments([]);
                setMainComponent("Sent");
                await getCloudProvider("pol", process.env.REACT_APP_NETWORK, "full").waitForTransaction(hash, 1, 300000).then(async (receipt) => {
                    console.log(receipt);
                    snackbar.setSnackbarActive(true);
                    snackbar.setSnackbarMessage("Transaction successful");
                    snackbar.setSnackbarIcon("fa-check");
                    snackbar.setSnackbarSpin(false);
                    snackbar.setSnackbarLink("https://polygonscan.com/tx/" + hash);
                });
            } catch (e) { }
            setButtonLoading(false);
            setSignInfo(false);
        }
    }

    async function signMessageWithParam(prepend, param1, address) {
        let message = prepend + param1 + "\n\nProtocol: " + Variables().w3MailAddress.toLowerCase();
        const signature = await signMessage({ message: message });
        console.log(signature);
        return signature
    }
    return (
        <div className="h-full w-full">
            <div className="z-1 h-full flex-col justify-between items-between">
                <div className="flex-1 w-full" id="upper">
                    <div className={`px-8 py-4 pt-8 w-full`}>
                        <div className="flex justify-between items-center w-full border-b border-b-gray-200 pb-8">
                            <div>
                                <p className="font-bold text-4xl">{"Compose"}</p>
                                {/* <p className="text-gray-500 text-sm mt-2">Showing {emailsArray.length == 10 ? "10" : emailsArray.length} emails</p> */}
                            </div>
                        </div>
                        <div className={"block"} style={{ height: "calc(100%)" }}>
                            <div className="flex py-4 border-b border-gray-200 items-center">
                                <p className="text-md text-gray-500">To</p>
                                <div className="flex justify-between w-full ml-3 items-center">
                                    <div className="w-full">
                                        {toAddress != "" ? (
                                            <div className="tooltip cursor-pointer" onClick={() => copy(toAddress)}>
                                                <div className="flex border border-gray-200 bg-gray-100 rounded-full items-center py-2 px-4 w-fit">
                                                    <div>
                                                        <p className="font-semibold text-sm md:text-xs lg:text-sm text-black">{toDomain != "" ? toDomain : shortenaddress(toAddress)}</p>
                                                        {/* {toDomain != "" ? (
                                                    <p className="text-xs md:text-xxs lg:text-xs text-gray-500 mt-px">{shortenaddress(toAddress)}</p>
                                                ) : (<div />)} */}
                                                    </div>
                                                </div>
                                                <p className="tooltiptext text-xs px-10">{copied ? "Copied successfully" : toAddress + " (Click to copy)"}</p>
                                            </div>
                                        ) : (
                                            <input type="text"
                                                placeholder="Address (or Web3 username)"
                                                className="bg-transparent ml-0 border-0 w-full rounded-lg py-1 pl-3 outline-none text-md"
                                                onChange={(e) => parseAddress(e.target.value)}
                                                value={toValue}></input>
                                        )}

                                        {registeredError ? (
                                            <div className="flex mt-2 items-center ml-3">
                                                <FontAwesomeIcon icon={['fas', 'fa-info-circle']} className="text-main text-xs md:text-xxs lg:text-xs mr-1" />
                                                <p className="font-bold text-xs md:text-xxs lg:text-xs text-main whitespace-nowrap">The address is not registered</p>
                                            </div>
                                        ) : (
                                            <div />
                                        )}
                                    </div>
                                    <div className="pl-2">
                                        {toLoading ? (
                                            <div onClick={() => clearInput()} className="tinyButtons">
                                                <FontAwesomeIcon icon={['fas', 'fa-circle-notch']} className="text-gray-800 text-base md:text-md" spin />
                                            </div>
                                        ) : (
                                            <div>
                                                {toAddress != "" ? (
                                                    <div onClick={() => clearInput()} className="tinyButtons">
                                                        <FontAwesomeIcon icon={['fas', 'fa-xmark']} className="text-gray-800 text-base md:text-md" />
                                                    </div>
                                                ) : (
                                                    <div />
                                                )}
                                            </div>
                                        )}


                                    </div>

                                </div>
                            </div>
                            <div className="flex py-4 border-b border-gray-200 items-center">
                                <p className="text-md text-gray-500">Subject</p>
                                <input
                                    type="text"
                                    placeholder="Subject"
                                    value={emailSubject}
                                    onChange={(e) => setEmailSubject(e.target.value)}
                                    className="ml-2 bg-transparent border-0 w-full rounded-lg text-black p-1 outline-none text-md"
                                />
                            </div>
                            <div className="h-full" >
                                <RichText htmlValue={htmlValue} setHtmlValue={setHtmlValue} paramBody={paramBody} />
                            </div>
                        </div>
                    </div>
                    <div className="mx-6 border-t border-t-gray-200 pt-4 pb-4 flex justify-between" style={{}}>
                        <div>
                            <button onClick={() => sendEmail()} className="bg-black rounded-full p-2 px-4">
                                {buttonLoading ? (
                                    <FontAwesomeIcon icon={['fas', 'fa-circle-notch']} className="text-white text-sm" spin />
                                ) : (<span className="text-white font-bold text-sm">Send</span>)}
                            </button>
                        </div>
                        {signInfo && (
                            <div className="onlyvertcenter" style={{ marginTop: "1%", marginBottom: "2%" }}>
                                <FontAwesomeIcon icon={['fas', 'fa-info-circle']} className="text-main text-sm mr-1" />
                                <p className="font-bold text-main text-sm whitespace-nowrap">Sign the action in your wallet.</p>
                            </div>
                        )}


                    </div>
                </div>
            </div >
        </div >
    );
}


const RichText = ({ htmlValue, setHtmlValue, paramBody }) => {
    const [value, setValue] = useState(RichTextEditor.createEmptyValue());
    const [html, setHtml] = useState(false);


    const toolbarConfig = {
        // Optionally specify the groups to display (displayed in the order listed).
        display: ['INLINE_STYLE_BUTTONS', 'BLOCK_TYPE_BUTTONS'],
        INLINE_STYLE_BUTTONS: [
            { label: 'Bold', style: 'BOLD', className: 'editorButton' },
            { label: 'Italic', style: 'ITALIC', className: 'editorButton' },
            { label: 'Underline', style: 'UNDERLINE', className: 'editorButton' }
        ],
        BLOCK_TYPE_DROPDOWN: [
            { label: 'Normal', style: 'unstyled' },
            { label: 'Heading Large', style: 'header-one' },
            { label: 'Heading Medium', style: 'header-two' },
            { label: 'Heading Small', style: 'header-three' }
        ],
        BLOCK_TYPE_BUTTONS: [
            { label: 'UL', style: 'unordered-list-item', className: 'editorButton' },
            { label: 'OL', style: 'ordered-list-item', className: 'editorButton' }
        ],
        LINK_BUTTONS: [
            { label: 'Link', className: 'editorButton' },
            { label: 'Unlink', className: 'editorButton' }
        ]
    };

    const onText = (value) => {
        console.log(value.toString('html'));
        setValue(value);
        setHtmlValue(value.toString('html'));
        // if (this.props.onChange) {
        //   // Send the changes up to the parent component as an HTML string.
        //   // This is here to demonstrate using `.toString()` but in a real app it
        //   // would be better to avoid generating a string on each change.
        //   this.props.onChange(
        //     value.toString('html')
        //   );
        // }
    };

    const onHtml = (value) => {
        console.log(value);
        setValue(RichTextEditor.createValueFromString(value, 'html'));
        setHtmlValue(value);
    }

    useEffect(() => {
        // if (paramBody != null) {
        //     setValue(paramBody);
        //     setHtmlValue(paramBody.toString('html'));
        // }
    }, [paramBody]);

    return (
        <div className="h-full">
            <div id="emailBody">
                <div style={{ display: !html ? "block" : "none" }} id="emailText" className="w-full">
                    <RichTextEditor
                        value={value}
                        onChange={onText}
                        toolbarConfig={toolbarConfig}
                        className="h-full w-full break-words"
                        placeholder="Body"
                    />
                </div>
                <div style={{ display: html ? "block" : "none" }} className="h-full">
                    <textarea
                        placeholder="HTML"
                        value={htmlValue}
                        onChange={(e) => onHtml(e.target.value)}
                        className="py-4 text-md h-full w-full border-0 outline-none resize-none"
                        style={{ fontFamily: "monospace" }}
                        autoFocus
                        id="emailHtml"
                    />
                </div>
            </div>
            <div className="flex items-center justify-between w-full gap-x-4">
                <div >
                    <p className="text-xs text-gray-500">Formatting options</p>
                    <div id="composeButtons">

                    </div>
                </div>
                <div className="gap-x-4 flex items-center">
                    <p className={html ? "text-sm text-main cursor-pointer font-semibold" : "text-sm font-semibold"} onClick={() => setHtml(false)}>Text</p>
                    <p className={html ? "text-sm font-semibold" : "text-sm text-main cursor-pointer font-semibold"} onClick={() => setHtml(true)}>HTML</p>
                </div>
            </div>
        </div>
    )
}