// import { decryptWithPassword } from "../0.smallfunctions/encryption";
// import { getcurrentaddress } from "../1.loginsystem/connectweb3";
// import { callW3Api } from "../3.api/callW3Api";
// import Web3 from "web3";
// import { loginPrepend, privateKeyEncryptPrepend } from "../0.smallfunctions/prepends";


// export async function getEncryptedPrivateKey() {
//     let address = await getcurrentaddress();
//     let encryptedPrivateKey = await callW3Api("/account/proxy/getEncryptedPrivateKey", { address: address });
//     if (encryptedPrivateKey == "null") {
//         return "null";
//     } else {
//         return encryptedPrivateKey;
//     }
// }

export async function getDecryptedPrivateKey(setProgressText) {
    if (localStorage.getItem("accountStorage") != null) {
        let accountStorage = JSON.parse(localStorage.getItem("accountStorage"));
        let privateKey = accountStorage.proxy.privateKey;
        return privateKey;
    } else {
        return "null";
    }
}


export async function getJwtToken() {
    if (localStorage.getItem("jwtToken") == null) {
        return "null";
    } else {
        return localStorage.getItem("jwtToken");
    }
}

// async function signMessage(prepend, address) {
//     const web3 = new Web3(await getprovider());
//     let message = prepend;
//     const signature = await web3.eth.personal.sign(message, address);
//     console.log(signature);
//     return signature
// }