import React, { useState, useContext, useEffect } from "react";
import Footer from "../0.global/footer/footer";
import Header from "../0.global/header/header";
import Hero from "./partials/hero";
import UseCases from "./partials/useCases";
import GradientImg from "../../1.resources/3.files/images/herogradient.svg";
import W3 from "./partials/w3";
import OpenSource from "./partials/opensource";
import Devices from "./partials/devices";
import Questions from "./partials/questions";

class Home extends React.Component {

    componentDidMount() {
        document.getElementById("title").innerHTML = "W3 Mail - Most secure email protocol built on the Blockchain technology"

        window.addEventListener("scroll", function () {
            if (window.location.pathname == "/") {
                if (document.getElementById("main").getBoundingClientRect().top < 0) {
                    document.getElementById("header").className = "hidden lg:block px-40 py-1 px-10 bg-white border-b border-b-neutral-200";
                } else {
                    document.getElementById("header").className = "hidden lg:block px-40 py-1 px-10";
                }
            }
        });
    }
    render() {
        return (
            <div>
                <div className="">
                    <div className="">
                        <div style={{ backgroundImage: `url(${GradientImg})` }} className="bg-cover bg-center bg-no-repeat bg-fixed" id="main">
                            <Header />
                            <Hero />
                            <div className="bg-white">
                                <UseCases />
                                <Devices />
                                <Questions />
                                <W3 />
                            </div>
                        </div>
                    </div>
                    {/* <W3 /> */}
                    <div className="bg-black">
                        <Footer />
                    </div>
                </div>
            </div >
        );
    }
}

export default Home;

// function isElementInViewport(el) {
//     var rect = el.getBoundingClientRect();
//     return (
//         rect.top >= 0 &&
//         rect.left >= 0 &&
//         rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
//         rect.right <= (window.innerWidth || document.documentElement.clientWidth)
//     );
// }