import React from 'react';
const Iframe = (props) => {
    let iframe_ref = null;
    const writeHTML = (frame) => {
        if (!frame) {
            return;
        }
        iframe_ref = frame;
        let doc = frame.contentDocument || frame.contentWindow.document;
        doc.open();
        doc.write(props.content);
        doc.close();
        frame.style.width = '100%';
        frame.style.height = `${frame.contentWindow.document.body.scrollHeight}px`;
        doc.body.style.fontFamily = "system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif";

    };
    return (
        <iframe id="iframe" scrolling="no" frameBorder="0" ref={writeHTML} style={{ width: "100%" }} width={"100%"}
        />
    );
};
export default Iframe;