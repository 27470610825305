import React, { useState, useContext, useEffect } from "react";
import Snackbar from "../../../0.global/snackbar/snackbar";
import LoginModal from "../1.login/5.login";
import InboxHeader from "./header/header";
import HeaderRight from "./header/headerRight";
import HeaderSearch from "./header/headerSearch";
import InboxCompose from "./main/partials/compose";
import InboxSection from "./main/section";
import InboxSidebar from "./sidebar/sidebar";
import Logo from "../../../../1.resources/3.files/logo/w3mail.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { HiOutlinePencilAlt } from 'react-icons/hi';

class InboxMain extends React.Component {

    componentDidMount() {
        document.getElementById("main").style.minHeight = window.innerHeight - document.getElementById("header").scrollHeight - 80 + "px";
        document.getElementById("sidebar").style.minHeight = window.innerHeight + "px";

        try {
            document.getElementById("upper").style.minHeight = window.innerHeight - 160 + "px";
        } catch (e) { }
    }
    render() {
        return (
            <div className="bg-white min-h-screen">
                {/* <div id="header" className="">
                    <InboxHeader />
                </div> */}

                <Main />
            </div>
        );
    }
}

export default InboxMain;


const Main = () => {
    const [mainComponent, setMainComponent] = useState("Inbox");
    const [mobileSidebar, setMobileSidebar] = useState(false);
    const [snackbarActive, setSnackbarActive] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState("");
    const [snackbarLink, setSnackbarLink] = React.useState("");
    const [snackbarIcon, setSnackbarIcon] = React.useState("");
    const [snackbarSpin, setSnackbarSpin] = React.useState(false);
    const [loginModal, setLoginModal] = React.useState(false);

    function title() {
        let title;
        if (mainComponent == "") {
            title = "Inbox - W3 Mail"
        } else if (mainComponent == "Inbox") {
            title = "Inbox - W3 Mail"
        } else if (mainComponent == "Sent") {
            title = "Sent - W3 Mail"
        } else if (mainComponent == "Starred") {
            title = "Flagged - W3 Mail"
        } else if (mainComponent == "Bin") {
            title = "Bin - W3 Mail"
        } else {
            title = "Inbox - W3 Mail"
        }
        document.getElementById("title").innerHTML = title;
    }
    useEffect(() => {
        title();
    }, [mainComponent])

    useEffect(() => {
        if (localStorage.getItem("jwtToken") == null) {
            setLoginModal(true);
        }
    }, [])

    return (
        <div className="flex-1">
            <InboxHeader mobileSidebar={mobileSidebar} setMobileSidebar={setMobileSidebar} setMainComponent={setMainComponent} />
            <Snackbar snackbarActive={snackbarActive} setSnackbarActive={setSnackbarActive} message={snackbarMessage} icon={snackbarIcon} link={snackbarLink} spin={snackbarSpin} />

            <div className="flex justify-between mb-8">
                <div className="hidden lg:block w-[340px] px-4 pt-5 fixed">
                    <InboxSidebar setMainComponent={setMainComponent} mainComponent={mainComponent} />
                </div>

                <div className="w-full mr-4 ml-4 lg:ml-[340px] pb-4"  >



                    <div className="flex items-center py-4 justify-between" id="header">
                        <a href='/' className='flex items-center cursor-pointer w-full lg:hidden'>
                            <img src={Logo} alt="Logo" className="w-10" />
                            <p className='font-bold text-black text-2xl ml-4 whitespace-nowrap'>W3 <span className='text-gray-500 font-semibold tracking-widest'>Mail</span></p>
                        </a>

                        <div className='hidden lg:block w-full'>
                            <HeaderSearch />
                        </div>

                        <div className="flex items-center justify-end w-full gap-x-4">
                            <HeaderRight />
                            <div className="bg-gray-400 w-10 h-10 flex justify-center items-center lg:hidden" style={{ borderRadius: "14px" }}>
                                <FontAwesomeIcon icon={["fas", "fa-bars"]} className="text-white" onClick={() => setMobileSidebar(!mobileSidebar)} />
                            </div>
                        </div>
                    </div>
                    <div id="main">
                        <InboxSection mainComponent={mainComponent} setMainComponent={setMainComponent} snackbar={{ setSnackbarActive: setSnackbarActive, setSnackbarMessage: setSnackbarMessage, setSnackbarLink: setSnackbarLink, setSnackbarIcon: setSnackbarIcon, setSnackbarSpin: setSnackbarSpin }} />
                    </div>
                </div>
            </div>
            <LoginModal isOpen={loginModal} setIsOpen={setLoginModal} />
        </div>
    )
}