const { ethers } = require("ethers");
const { getCloudProvider } = require("./cloudProvider");
const { Variables } = require("./variables");


const CloudContracts = (network, chain, type) => {
    let provider = getCloudProvider(network, chain, type);

    let w3MailContract = new ethers.Contract(Variables().w3MailAddress, Variables().w3MailAbi, provider);

    return { w3MailContract };
}

module.exports = CloudContracts;