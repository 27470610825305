const { encryptWithPublicKeys, decryptWithPrivateKeys, encryptWithPassword, decryptWithPassword } = require("../0.smallfunctions/encryption");
const EthCrypto = require('eth-crypto');
const CloudContracts = require("../2.contracts/cloudContracts");

export async function encryptEmail(email, fromAddress, toAddress) {
    let keys = await Promise.all([CloudContracts("pol", process.env.REACT_APP_NETWORK, "full").w3MailContract.getProxyKey(fromAddress),
    CloudContracts("pol", process.env.REACT_APP_NETWORK, "full").w3MailContract.getProxyKey(toAddress)])

    let fromKey = keys[0].substring(1 + 1);
    let toKey = keys[1].substring(1 + 1);
    let randomPassword = generateRandomPassword(256);
    let encryptedEmail = await encryptWithPassword(JSON.stringify(email), randomPassword);

    let fromEncryptedPassword = await encryptWithPublicKeys(randomPassword, fromKey);
    let toEncryptedPassword = await encryptWithPublicKeys(randomPassword, toKey);

    return { encryptedEmail, fromEncryptedPassword, toEncryptedPassword }
}

export async function decryptEmail(message, privateKey) {
    const decrypted = await EthCrypto.decryptWithPrivateKey(
        privateKey, // publicKey
        JSON.parse(message)
    );
    return JSON.parse(decrypted)
}

export function generateRandomPassword(len) {
    var length = (len) ? (len) : (10);
    var string = "abcdefghijklmnopqrstuvwxyz"; //to upper 
    var numeric = '0123456789';
    var punctuation = '!@#$%^&*()_+~`|}{[]\:;?><,./-=';
    var password = "";
    var character = "";
    var crunch = true;
    while (password.length < length) {
        let entity1 = Math.ceil(string.length * Math.random() * Math.random());
        let entity2 = Math.ceil(numeric.length * Math.random() * Math.random());
        let entity3 = Math.ceil(punctuation.length * Math.random() * Math.random());
        let hold = string.charAt(entity1);
        hold = (password.length % 2 == 0) ? (hold.toUpperCase()) : (hold);
        character += hold;
        character += numeric.charAt(entity2);
        character += punctuation.charAt(entity3);
        password = character;
    }
    password = password.split('').sort(function () { return 0.5 - Math.random() }).join('');
    return password.substring(0, len);
}

