import React, { useState, useContext, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Connect from "./partials/1.login/1.connect";
import Sign1 from "./partials/1.login/2.sign1";
import WnsLogo from "../0.global/logo/wnsLogo";
import Sign2 from "./partials/1.login/3.sign2";
import { useAuth } from "../../3.components/1.wrappers/1.auth";
import { useAccount } from 'wagmi'
import Header from "../0.global/header/header";
import CloudContracts from "../../1.resources/2.js/0.global/2.contracts/cloudContracts";
import Logo from "../../1.resources/3.files/logo/w3mail6.png";
import Web3 from "web3";
import { decryptWithPassword, encryptWithPassword } from "../../1.resources/2.js/0.global/0.smallfunctions/encryption";
import { ethers } from "ethers";
import Switch from "./partials/1.login/4.switch";
import InboxMain from "./partials/2.inbox/main";
import Loading from "./partials/1.login/0.loading";

const Inbox = ({ }) => {

    useEffect(() => {
        document.getElementById("title").innerHTML = "Inbox - W3 Mail"
    }, [])

    return (
        <div className="w-screen h-screen flex justify-center items-center bg-[#111111]">
            <div>
                <img src={Logo} className="w-20 mx-auto bg-white rounded-lg" />
                <p className="font-bold text-xl text-center mt-10 text-white">W3 Mail is under maintainence</p>
            </div>
        </div>
    );

}

export default Inbox;


