import { decryptWithPassword } from "../0.smallfunctions/encryption";
import { getcurrentaddress } from "../1.loginsystem/connectweb3";
import { callW3Api } from "../3.api/callW3Api";
import { decryptEmail } from "./encryptEmail";
import { getDecryptedPrivateKey } from "./getProxy";
const EthCrypto = require('eth-crypto');


export async function getAllEmails({ type, limit, skip }) {
    let address = await getcurrentaddress();
    let allEmails = await callW3Api("/email/get/all", { address: address, type: type, limit: limit, skip: skip });

    if (type != "Bin") {
        if (type == "Sent") {
            allEmails = allEmails.filter(function (obj) {
                return obj.fromBin !== true;
            });
        } else {
            allEmails = allEmails.filter(function (obj) {
                return obj.toBin !== true;
            });
        }
    }
    let emailContentPromise = []
    for (let i = 0; i < allEmails.length; i++) {
        emailContentPromise.push(fetch("https://w3.infura-ipfs.io/ipfs/" + allEmails[i].emailHash));
    }
    let emailContentBlurry = await Promise.all(emailContentPromise);

    let emailContentFullPromise = []
    for (let i = 0; i < emailContentBlurry.length; i++) {
        emailContentFullPromise.push(emailContentBlurry[i].json());
    }
    let emailContent = await Promise.allSettled(emailContentFullPromise);
    //let emailContent = await Promise.all(emailContentFullPromise);


    let privateKey = await getDecryptedPrivateKey();
    let passwordKey = "toEncryptedPassword";
    if (type == "Sent") {
        passwordKey = "fromEncryptedPassword";
    }

    let decryptedEmailContentPromise = []
    for (let i = 0; i < emailContent.length; i++) {
        if (emailContent[i].status == "fulfilled") {
            if (type == "Sent") {
                decryptedEmailContentPromise.push(decryptEmail(emailContent[i].value.fromEncryptedPassword, privateKey));
            } else {
                decryptedEmailContentPromise.push(decryptEmail(emailContent[i].value.toEncryptedPassword, privateKey));
            }
        }
    }
    let decryptedEmailContent = await Promise.all(decryptedEmailContentPromise);
    let emailsBodyPromise = []
    for (let i = 0; i < decryptedEmailContent.length; i++) {
        emailsBodyPromise.push(decryptWithPassword(emailContent[i].value.encryptEmail, decryptedEmailContent[i]))
    }
    let emailsBody = await Promise.all(emailsBodyPromise);
    let finalEmails = []
    for (let i = 0; i < emailsBody.length; i++) {
        try {
            let val = JSON.parse(emailsBody[i]);
            finalEmails.push(val);
        } catch (e) {
            finalEmails.push(emailsBody[i]);
        }

    }

    let final = []
    for (let i = 0; i < finalEmails.length; i++) {
        if (emailContent[i].status == "fulfilled") {
            allEmails[i].email = finalEmails[i];
            final.push(allEmails[i])
        }
    }
    return final;
}


export async function getDecryptedEmails(allEmails, type) {

    if (type != "Bin") {
        if (type == "Sent") {
            allEmails = allEmails.filter(function (obj) {
                return obj.fromBin !== true;
            });
        } else {
            allEmails = allEmails.filter(function (obj) {
                return obj.toBin !== true;
            });
        }
    }

    let emailContentPromise = []
    for (let i = 0; i < allEmails.length; i++) {
        emailContentPromise.push(fetch("https://w3.infura-ipfs.io/ipfs/" + allEmails[i].emailHash));
    }
    let emailContentBlurry = await Promise.all(emailContentPromise);

    let emailContentFullPromise = []
    for (let i = 0; i < emailContentBlurry.length; i++) {
        emailContentFullPromise.push(emailContentBlurry[i].json());
    }
    let emailContent = await Promise.allSettled(emailContentFullPromise);
    //let emailContent = await Promise.all(emailContentFullPromise);
    let privateKey = await getDecryptedPrivateKey();
    let passwordKey = "toEncryptedPassword";
    if (type == "Sent") {
        passwordKey = "fromEncryptedPassword";
    }

    let decryptedEmailContentPromise = []
    for (let i = 0; i < emailContent.length; i++) {
        if (emailContent[i].status == "fulfilled") {
            if (type == "Sent") {
                decryptedEmailContentPromise.push(decryptEmail(emailContent[i].value.fromEncryptedPassword, privateKey));
            } else {
                decryptedEmailContentPromise.push(decryptEmail(emailContent[i].value.toEncryptedPassword, privateKey));
            }
        }
    }

    let decryptedEmailContent = await Promise.all(decryptedEmailContentPromise);
    let emailsBodyPromise = []
    for (let i = 0; i < decryptedEmailContent.length; i++) {
        emailsBodyPromise.push(decryptWithPassword(emailContent[i].value.encryptEmail, decryptedEmailContent[i]))
    }
    let emailsBody = await Promise.all(emailsBodyPromise);
    let finalEmails = []
    for (let i = 0; i < emailsBody.length; i++) {
        try {
            let val = JSON.parse(emailsBody[i]);
            finalEmails.push(val);
        } catch (e) {
            finalEmails.push(emailsBody[i]);
        }

    }

    let final = []
    for (let i = 0; i < finalEmails.length; i++) {
        if (emailContent[i].status == "fulfilled") {
            allEmails[i].email = finalEmails[i];
            final.push(allEmails[i])
        }
    }
    return final;
}