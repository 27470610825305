import React, { useState, useEffect, useContext } from "react";
import Home from "./2.views/1.home/home";
import Inbox from "./2.views/2.inbox/inbox";

const Main = ({ }) => {

  const [connectModal, setConnectModal] = useState(false);
  const [walletConnected, setWalletConnected] = useState(false);
  const [path, setPath] = useState(["", ""]);

  useEffect(() => {
    let url = window.location.pathname;
    let array = url.split("/");
    setPath(array);
  }, []);

  return (
    <div id="top" className="overflow-x-clip">
      {path != null ? (
        path[1] == "" ? (
          <Home />
        ) : path[1] == "inbox" ? (
          <Inbox />
        ) : (
          <div />
        )
      ) : (<div />)}
    </div >
  );

}

export default Main;


/* position: "absolute", top: "50%", transform: "translateY(-50%)" */