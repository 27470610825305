import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ConnectButton } from "@rainbow-me/rainbowkit";
import { ConnectWallet } from "../../../../0.global/wallet/connectWallet";

const HeaderRight = ({ connectModal, setConnectModal }) => {

    return (
        <div className="flex gap-2">
            {/* <a href="/cart" className="cursor-pointer w-[50px] h-[50px] rounded-full shadowDiv flex justify-center items-center">
                <FontAwesomeIcon icon={['fas', "fa-shopping-bag"]} />
            </a> */}

            <div className="border border-gray-200 rounded-2xl">
                <ConnectWallet />
            </div>
        </div>
    );

}


export default HeaderRight;
